import React from "react";

function NumberFour(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="83"
      height="82"
      fill="none"
      viewBox="0 0 83 82"
      {...props}
    >
      <path
        fill="#006180"
        d="M.667 24c0-13.255 10.745-24 24-24h34c13.255 0 24 10.745 24 24v34c0 13.255-10.745 24-24 24h-34c-13.255 0-24-10.745-24-24V24z"
      ></path>
      <path
        fill="#fff"
        d="M55.267 52.85h-4.1V60h-8.35v-7.15h-14.8v-6.1l15.3-22.45h7.85v22.05h4.1v6.5zM42.817 41.2v-1.75c.033-.7.05-1.383.05-2.05.033-.7.066-1.3.1-1.8.033-.533.066-.867.1-1h-.25c-.3.7-.6 1.333-.9 1.9-.3.533-.667 1.133-1.1 1.8l-5.35 8.05h7.35V41.2z"
      ></path>
    </svg>
  );
}

export default NumberFour;
