/** @jsxImportSource @emotion/react */
import { colors } from "../theme";
import { css } from "@emotion/react";
import Deal from "../icons/Deal";
import { InfoField } from "./InfoField";
import Phone from "../icons/Phone";
import Mail from "../icons/Mail";
import Book from "../icons/Book";
import { handleCalendry } from "../utils/Calendry";
import "../App.css";
import Location from "../icons/Location";

const ContactUs = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        id="contacts"
        className="contacts"
        css={css({
          display: "flex",
          maxWidth: "1440px",
          justifyContent: "space-between",
          padding: "40px 24px 0",
          backgroundColor: colors.background,
          alignItems: "center",
          width: "100%",
          gap: "1rem",
          marginTop: "2rem",
          marginBottom: "2rem",
          "@media (max-width:768px)": {
            flexDirection: "column",
          },
        })}
      >
        <div
          css={css({
            paddingLeft: "40px",
            maxWidth: "50%",
            height: "100%",
            width: "600px",
            "@media (max-width:768px)": {
              maxWidth: "85%",
              padding: "0",
            },
          })}
        >
          <Deal />
        </div>
        <div
          css={css({
            maxWidth: window.location.pathname === "/telegram-miniapp" ? "41%" : "50%",

            "@media (max-width:768px)": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "32px",
              maxWidth: "85%",
            },
          })}
        >
          <h4
            css={css({
              fontSize: "32px",
              fontWeight: "normal",
              marginBottom: "14px",
              "@media (max-width:768px)": {
                fontSize: "24px",
                marginBottom: "16px",
              },
            })}
          >
            Contact us for any question :)
          </h4>
          <p style={{ fontSize: "20px", fontWeight: "normal" }}>
            Book an online meeting and let’s discuss about possibilities of your ideas in the
            internet world.
          </p>
          <div
            css={css({
              display: "flex",
              flexDirection: "column",
              width: "330px",
              alignItems: "center",
              paddingTop: "25px",
              "@media (max-width:768px)": {},
            })}
            method="post"
          >
            <InfoField isPhone icon={<Phone />} info="+372 5310 8632" />
            <InfoField isMail icon={<Mail />} info="info@visionforge.ee" />
            <InfoField
              hasBtn
              isBook
              icon={<Book />}
              info="Book a Meeting"
              onClick={handleCalendry()}
            />
            <InfoField
              isAdress
              id="location"
              hasTitle
              readOnly
              country={"🇪🇪 Estonia"}
              icon={<Location />}
              info="Harju maakond, Tallinn, Kesklinna linnaosa, Pärnu mnt 139c, 11317"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
