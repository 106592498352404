import * as React from "react";

const CurveSecond = (props) => (
  <svg
    width={321}
    height={321}
    viewBox="0 0 321 321"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M232.517 276.054C240.2 233.019 254.285 243.625 287.577 214.547C348.497 161.338 322.048 17.8424 240.2 38.7785C189.019 51.8703 142.886 -26.3561 80.144 9.70053C52.0857 25.825 77.583 99.2606 33.843 118.746C-12.1516 139.236 -7.85257 188.926 33.843 214.547C75.5386 240.168 54.7694 247.828 89.107 293.501C123.445 339.174 224.447 321.259 232.517 276.054Z"
      fill="white"
      fillOpacity={0.2}
    />
  </svg>
);
export default CurveSecond;
