/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "../App.css";
import { colors } from "../theme";

const Container = ({ children, ...props }) => (
  <div
    css={css({
      display: "flex",
      alignItems: "center",
      padding: "0 32px",
      gap: "1rem",
      "@media (max-width: 768px)": {
        flexDirection: "column",
        padding: "0 28px",
      },
      "@media (max-width: 1024px)": {
        padding: "0 28px",
      },
    })}
    {...props}
  >
    {children}
  </div>
);

const Heading = ({ children, ...props }) => (
  <h2
    css={css({
      textAlign: "center",
      fontSize: "32px",
      fontWeight: "normal",
      "@media (max-width: 768px)": {
        fontSize: "26px",
        fontWeight: "bolder",
        lineHeight: "38px",
      },
      "@media (min-width: 768px) and (max-width: 1280px)": {
        lineHeight: "38px",
      },
    })}
    {...props}
  >
    {children}
  </h2>
);

const Text = ({ children, ...props }) => (
  <p
    css={css({
      textAlign: "center",
    })}
    {...props}
  >
    {children}
  </p>
);

export const AboutSection = ({ heading, content, imageSrc, containerStyle }) => {
  return (
    <Container css={containerStyle}>
      <img
        src={imageSrc}
        alt={heading}
        css={css({
          maxWidth: "50%",
          "@media (max-width: 768px)": {
            maxWidth: "100%",
            width: "100%",
            height: "auto",
          },
        })}
      />
      <div
        css={css({
          padding: "0 2rem",
          "@media (min-width: 768px) and (max-width: 1280px)": {
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          },
        })}
      >
        <Heading>{heading}</Heading>
        <Text>{content}</Text>
      </div>
    </Container>
  );
};

const About = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        css={css({
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "2rem",
          marginLeft: "6rem",
          maxWidth: "1440px",
          "@media (max-width: 768px)": {
            gap: "1rem",
            marginLeft: "0",
          },
        })}
      >
        <div>
          <h2
            css={css({
              color: colors.primary,
              fontSize: "50px",
              textAlign: "center",
              fontWeight: "bolder",
              fontFamily: "Noto Sans",
              marginRight: "7rem",
              "@media (max-width: 768px)": {
                fontSize: "36px",
                marginRight: "0",
              },
            })}
          >
            Read more about miniapps
          </h2>
        </div>
        <AboutSection
          heading="What Are They?"
          content="TMAs are web apps that run inside Telegram chats. Developers can use JavaScript to create flexible interfaces that launch directly within Telegram. These Mini Apps can replace websites entirely. And that is not all of it, they have better Ui and Ux totally!"
          imageSrc="./img/Phone.png"
        />
        <AboutSection
          heading="Launching Mini Apps"
          content="You can launch Mini Apps in various ways, such as from keyboard buttons, inline buttons, menu buttons, links, and the attachment menu. this will help you to have a good accessibility for opening an mini app"
          imageSrc="./img/Bot.png"
          containerStyle={css({
            "@media (min-width: 768px)": {
              flexDirection: "row-reverse",
            },
          })}
        />
        <AboutSection
          heading="Resources and Communities"
          content="Explore the Awesome Telegram Mini Apps GitHub repository for curated resources, articles, videos, UI kits, libraries, templates, and more related to TMAs2."
          imageSrc="./img/Store.png"
        />
      </div>
    </div>
  );
};

export default About;
