import React from "react";
import { motion } from "framer-motion";

function Developer(props) {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 171 170" {...props}>
        <rect
          width="170"
          height="170"
          x="0.5"
          fill="url(#paint0_linear_1074_1298)"
          rx="43.796"
        ></rect>
        <motion.path
          stroke="#fff"
          strokeWidth="7.299"
          d="M117.218 62.04l24.455 24.456-24.455 24.456"
          animate={{ x: [0, 15, 0, 2, 0] }}
          transition={{ duration: 1, repeat: Infinity, ease: [0.22, 1, 0.36, 1], repeatDelay: 2 }}
        ></motion.path>
        <motion.path
          stroke="#fff"
          strokeWidth="7.299"
          d="M56.045 62.04L31.59 86.496l24.456 24.456"
          animate={{ x: [0, -15, 0, -2, 0] }}
          transition={{ duration: 1, repeat: Infinity, ease: [0.22, 1, 0.36, 1], repeatDelay: 2 }}
        ></motion.path>
        <motion.path
          stroke="#fff"
          strokeWidth="7.299"
          d="M98.404 59.426l-23.546 54.141"
          animate={{ rotate: "360deg" }}
          transition={{ duration: 1, repeat: Infinity, ease: "easeInOut", repeatDelay: 2 }}
        ></motion.path>
        <defs>
          <linearGradient
            id="paint0_linear_1074_1298"
            x1="78.942"
            x2="84.73"
            y1="0"
            y2="170.026"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4DB6D6"></stop>
            <stop offset="1" stopColor="#116A87"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default Developer;
