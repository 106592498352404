import * as React from "react";
import { motion } from "framer-motion";

const SecondIcon = (props) => (
  <svg
    // width={821}
    // height={693}
    viewBox="0 0 997 713"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_2397_1495)">
      <rect x={304.183} y={-105.62} width={688.818} height={694.81} rx={344.409} fill="#2488FF" />
    </g>
    <g filter="url(#filter1_d_2397_1495)">
      <rect
        x={157.568}
        y={258.534}
        width={404.571}
        height={408.091}
        rx={202.286}
        fill="#2488FF"
        fillOpacity={0.5}
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter2_d_2397_1495)">
      <path
        d="M949.759 487.743C895.094 526.832 773.98 574.149 726.844 450.708C667.924 296.406 830.911 157.449 644.901 129.62C458.891 101.791 486.026 21.7443 507.167 -77.7471"
        stroke="#0AA2D9"
        strokeWidth={28.4444}
      />
    </g>
    <rect
      x={421.414}
      y={194.278}
      width={241.198}
      height={241.198}
      rx={120.599}
      fill="#0F0662"
      fillOpacity={0.49}
    />
    <motion.path
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5, type: "spring", stiffness: "200", damping: 10, delay: 0.1 }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M569.937 267.851C564.899 270.721 561.786 276.075 561.786 281.875V320.951C561.786 326.359 564.494 331.407 569.001 334.397L598.412 353.917C604.542 357.985 612.734 353.59 612.734 346.233V259.343C612.734 252.268 605.095 247.828 598.948 251.329L569.937 267.851ZM471.292 301.328C471.292 285.107 484.442 271.957 500.663 271.957H553.359V330.7H500.663C484.442 330.7 471.292 317.55 471.292 301.328ZM517.427 373.22L500.891 339.824H539.765L539.285 368.299C539.08 380.38 522.789 384.048 517.427 373.22Z"
      fill="white"
    />
    <motion.path
      // className="animated-path"
      d="M22.3194 452.21C33.0903 467.857 40.2153 478.302 43.6945 483.544C53.6275 498.511 55.4563 501.665 55.9066 503.859C56.0052 504.341 56.0845 505.447 55.6344 506.256C55.2542 506.94 54.6218 507.226 54.1713 507.391C53.7206 507.556 52.6792 507.85 51.837 507.927C42.2235 508.808 18.5819 509.272 7.637 509.306C3.00583 509.321 1.2031 507.985 0.764612 506.449C-0.188349 503.11 1.94309 500.041 3.53279 496.339C6.01988 490.545 7.62381 487.234 10.0557 481.607C12.8664 475.103 15.7017 478.451 19.8082 481.093C20.8827 481.784 37.5827 494.167 38.8982 494.222C39.0629 494.229 39.6573 494.153 39.904 493.752C40.1507 493.352 39.9504 492.867 39.7981 492.518C39.582 492.024 32.4941 484.83 18.5345 470.937C16.4612 468.906 15.2691 466.939 14.9575 465.036C14.6138 462.937 15.0246 458.701 15.4593 455.528C15.9927 451.636 16.0925 448.603 18.2058 448.471C19.3066 448.403 20.6778 449.649 22.3194 452.21Z"
      fill="white"
      initial={{ x: 0, scale: 1, rotate: 0, opacity: 0 }}
      animate={{ x: "30%", scale: 3, rotate: -80, opacity: 1 }}
      transition={{ duration: 1, delay: 1 }}
    />
    <defs>
      <filter
        id="filter0_d_2397_1495"
        x={304.183}
        y={-105.62}
        width={692.373}
        height={698.365}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2397_1495" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2397_1495"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2397_1495"
        x={157.568}
        y={258.534}
        width={408.127}
        height={411.646}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2397_1495" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2397_1495"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_2397_1495"
        x={477.915}
        y={-80.7031}
        width={483.673}
        height={628.715}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2397_1495" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2397_1495"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SecondIcon;
