import * as React from "react";

const TelegramAds = (props) => (
  <svg
    width={320}
    height={320}
    viewBox="0 0 320 320"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2042_1031)">
      <path
        d="M0 21.3334C0 9.55128 9.55126 0 21.3333 0H298.667C310.449 0 320 9.55126 320 21.3333V298.667C320 310.449 310.449 320 298.667 320H21.3334C9.55128 320 0 310.449 0 298.667V21.3334Z"
        fill="white"
      />
      <rect
        x={114.637}
        y={-38.9631}
        width={241.198}
        height={241.198}
        rx={120.599}
        fill="#0F0662"
        fillOpacity={0.49}
      />
      <g filter="url(#filter0_d_2042_1031)">
        <rect
          x={-10.6331}
          y={63.0901}
          width={341.266}
          height={401.152}
          rx={170.633}
          fill="#2488FF"
        />
      </g>
      <g filter="url(#filter1_d_2042_1031)">
        <rect
          x={326.486}
          y={249.113}
          width={117.387}
          height={332.052}
          rx={11.1443}
          transform="rotate(93.1672 326.486 249.113)"
          fill="#008044"
          fillOpacity={0.3}
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter2_d_2042_1031)">
        <rect
          x={-182.009}
          y={150.247}
          width={149.643}
          height={466.348}
          rx={11.1443}
          transform="rotate(-110.056 -182.009 150.247)"
          fill="#006180"
          fillOpacity={0.3}
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter3_d_2042_1031)">
        <path
          d="M310.434 -108.392C328.197 -74.8229 345.547 -3.41664 272.851 13.6565C181.981 34.9979 116.47 -66.7832 86.8311 35.2918C57.1921 137.367 14.4108 116.122 -39.7134 96.7647"
          stroke="#0AA2D9"
          strokeWidth={28.4444}
        />
      </g>
      <path
        d="M32.6285 205.053C80.5667 183.728 112.533 169.67 128.528 162.877C174.194 143.483 183.684 140.114 189.869 140.002C191.229 139.979 194.271 140.323 196.241 141.955C197.905 143.333 198.362 145.195 198.581 146.502C198.8 147.809 199.073 150.786 198.856 153.112C196.381 179.66 185.674 244.087 180.226 273.822C177.92 286.404 173.382 290.623 168.988 291.035C159.438 291.933 152.186 284.592 142.938 278.401C128.464 268.715 120.289 262.685 106.24 253.233C90.0042 242.309 100.529 236.305 109.782 226.492C112.203 223.925 154.278 184.849 155.093 181.305C155.194 180.861 155.289 179.209 154.327 178.337C153.366 177.464 151.947 177.762 150.923 177.999C149.471 178.336 126.35 193.939 81.5599 224.81C74.9969 229.411 69.0528 231.652 63.7269 231.535C57.8556 231.406 46.5615 228.146 38.1653 225.359C27.8672 221.941 19.6821 220.134 20.3948 214.329C20.7661 211.306 24.8438 208.214 32.6285 205.053Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M263.258 34.9141C259.364 37.1326 256.958 41.2706 256.958 45.7534V75.9557C256.958 80.1355 259.051 84.0375 262.534 86.3488L285.267 101.436C290.004 104.58 296.336 101.183 296.336 95.4969V28.3386C296.336 22.8702 290.432 19.4386 285.681 22.1447L263.258 34.9141ZM187.014 60.7895C187.014 48.2519 197.178 38.0882 209.715 38.0882H250.444V83.4908H209.715C197.178 83.4908 187.014 73.327 187.014 60.7895ZM222.673 116.355L209.892 90.5428H239.938L239.566 112.552C239.408 121.89 226.817 124.724 222.673 116.355Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2042_1031"
        x={-10.6331}
        y={63.0901}
        width={344.822}
        height={404.707}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2042_1031" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2042_1031"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2042_1031"
        x={-10.9463}
        y={231.365}
        width={340.389}
        height={140.579}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={4.44444} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2042_1031" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2042_1031"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_2042_1031"
        x={-230.183}
        y={-147.105}
        width={486.656}
        height={300.431}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={4.44444} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2042_1031" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2042_1031"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_2042_1031"
        x={-44.5028}
        y={-115.043}
        width={388.287}
        height={245.718}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2042_1031" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2042_1031"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_2042_1031">
        <path
          d="M0 21.3334C0 9.55128 9.55126 0 21.3333 0H298.667C310.449 0 320 9.55126 320 21.3333V298.667C320 310.449 310.449 320 298.667 320H21.3334C9.55128 320 0 310.449 0 298.667V21.3334Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
export default TelegramAds;
