import MiniAppHeader from "../components/MiniAppHeader";
import "../App.css";
import MiniAppMain from "../components/MiniAppMain";
import { colors } from "../theme";
import WaveSection from "../components/WaveSection";
import Book from "../components/Book";
import About from "../components/About";
import ContactUs from "../components/ContactUs";
import OurGoal from "../components/Ourgoal";
import MyFooter from "../components/MyFooter";
import useMetaTags from "../utils/useMetaTags";

const MiniApp = () => {
  useMetaTags(
    "Telegram Miniapp Developer | Build your own miniapp",
    "Build your Telegram MiniApp with the help of our engineers. Finding a MiniApp developer to create custom solutions is easy with VisionForge.",
  );

  return (
    <div style={{ backgroundColor: colors.background }} id="container">
      <MiniAppHeader />
      <MiniAppMain />
      <WaveSection />
      <Book />
      <About />
      <ContactUs />
      <OurGoal />
      <MyFooter />
    </div>
  );
};

export default MiniApp;
