import * as React from "react";

const WaveTopMobile = (props) => (
  <svg
    width={375}
    height={153}
    viewBox="0 0 375 153"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M538.437 56.3115L553.81 49.1124C601.004 27.0104 656.033 29.4356 701.1 55.6036C733.441 74.3823 758.129 103.959 770.826 139.135L775.598 152.357H-400.598C-400.598 126.861 -385.259 103.869 -361.719 94.0774L-310.286 72.6843C-285.304 62.2935 -257.934 59.0178 -231.206 63.2198L-56.4047 90.7009C-7.36713 98.4103 42.3748 81.9608 77.1478 46.5354C118.817 4.08445 181.241 -10.5099 237.412 9.06652L394.224 63.7182C441.419 80.1664 493.175 77.5083 538.437 56.3115Z"
      fill="#006180"
    />
  </svg>
);
export default WaveTopMobile;
