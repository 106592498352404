import React from "react";

function Security(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 320 321" {...props}>
      <g clipPath="url(#clip0_24_599)">
        <path
          fill="#fff"
          d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
        ></path>
        <g filter="url(#filter0_d_24_599)">
          <rect
            width="327.055"
            height="327.055"
            x="-39.312"
            y="-1.733"
            fill="#800062"
            fillOpacity="0.3"
            rx="119.107"
            shapeRendering="crispEdges"
            transform="rotate(-28.76 -39.312 -1.733)"
          ></rect>
        </g>
        <g filter="url(#filter1_d_24_599)">
          <rect
            width="371.101"
            height="211.661"
            x="-2.275"
            y="294.036"
            fill="#628000"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
            transform="rotate(-28.76 -2.275 294.036)"
          ></rect>
        </g>
        <g filter="url(#filter2_d_24_599)">
          <rect
            width="348.708"
            height="348.708"
            x="-189.889"
            y="108.792"
            fill="#FF6119"
            fillOpacity="0.3"
            rx="124.858"
            shapeRendering="crispEdges"
            transform="rotate(-28.76 -189.889 108.792)"
          ></rect>
        </g>
        <g filter="url(#filter3_d_24_599)">
          <path
            fill="#FF6119"
            fillRule="evenodd"
            d="M78.309 142.618h45.458V97.92c0-12.553-10.176-22.73-22.729-22.73-12.553 0-22.73 10.177-22.73 22.73v44.698zm-30.203 2.887c-14.624 5.907-24.943 20.239-24.943 36.98v27.577c0 22.018 17.85 39.867 39.867 39.867h76.016c22.018 0 39.867-17.849 39.867-39.867v-27.577c0-16.741-10.319-31.073-24.943-36.98V97.92c0-29.234-23.699-52.932-52.932-52.932S48.106 68.686 48.106 97.92v47.585z"
            clipRule="evenodd"
          ></path>
        </g>
        <g filter="url(#filter4_d_24_599)">
          <path
            fill="#428000"
            d="M273.028-45.582c2.328-4.762 9.115-4.762 11.442 0l12.412 25.39a6.368 6.368 0 008.306 3.023l25.829-11.471c4.844-2.152 10.043 2.21 8.765 7.354L332.97 6.143a6.368 6.368 0 004.419 7.655l27.16 7.814c5.094 1.466 6.273 8.15 1.987 11.269l-22.849 16.632a6.368 6.368 0 00-1.535 8.705l15.783 23.444c2.96 4.397-.433 10.274-5.721 9.91l-28.195-1.947a6.368 6.368 0 00-6.771 5.682l-2.98 28.104c-.558 5.271-6.936 7.592-10.752 3.914l-20.347-19.614a6.367 6.367 0 00-8.839 0l-20.348 19.614c-3.816 3.678-10.193 1.357-10.752-3.914l-2.979-28.104a6.37 6.37 0 00-6.772-5.682l-28.194 1.947c-5.288.364-8.682-5.513-5.722-9.91l15.783-23.444a6.368 6.368 0 00-1.535-8.705l-22.849-16.632c-4.285-3.12-3.107-9.803 1.987-11.269l27.16-7.814a6.368 6.368 0 004.419-7.655l-6.812-27.429c-1.278-5.144 3.921-9.506 8.765-7.355l25.829 11.472a6.368 6.368 0 008.306-3.023l12.412-25.39z"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_24_599"
          width="362.441"
          height="365.108"
          x="3.281"
          y="-116.501"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_599"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_599" result="shape"></feBlend>
        </filter>
        <filter
          id="filter1_d_24_599"
          width="422.747"
          height="362.356"
          x="1.71"
          y="119.467"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_599"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_599" result="shape"></feBlend>
        </filter>
        <filter
          id="filter2_d_24_599"
          width="387.728"
          height="390.395"
          x="-145.239"
          y="-14.338"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_599"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_599" result="shape"></feBlend>
        </filter>
        <filter
          id="filter3_d_24_599"
          width="159.306"
          height="208.497"
          x="23.163"
          y="44.988"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_599"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_599" result="shape"></feBlend>
        </filter>
        <filter
          id="filter4_d_24_599"
          width="184.376"
          height="184.492"
          x="188.339"
          y="-49.154"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_599"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_599" result="shape"></feBlend>
        </filter>
        <clipPath id="clip0_24_599">
          <path
            fill="#fff"
            d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Security;
