import React from "react";

function Evolution(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 320 321" {...props}>
      <g clipPath="url(#clip0_24_598)">
        <path
          fill="#fff"
          d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
        ></path>
        <rect
          width="166.566"
          height="166.566"
          x="87.165"
          y="-49.641"
          fill="#800062"
          fillOpacity="0.3"
          rx="83.283"
        ></rect>
        <g filter="url(#filter0_d_24_598)">
          <rect
            width="298.452"
            height="299.957"
            x="-44.721"
            y="73.915"
            fill="#006180"
            fillOpacity="0.3"
            rx="149.226"
            shapeRendering="crispEdges"
          ></rect>
        </g>
        <g filter="url(#filter1_d_24_598)">
          <rect
            width="117.387"
            height="332.052"
            x="-7.702"
            y="-11.806"
            fill="#628000"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
          ></rect>
        </g>
        <g filter="url(#filter2_d_24_598)">
          <rect
            width="149.643"
            height="349.272"
            x="188.485"
            y="-11.806"
            fill="#006180"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
          ></rect>
        </g>
        <g filter="url(#filter3_d_24_598)">
          <path
            fill="#006180"
            fillRule="evenodd"
            d="M94.241 100.966c-8.838 0-16.003 7.165-16.003 16.004v12.751c0 4.301-2.831 8.065-6.804 9.713-3.975 1.65-8.593.969-11.636-2.074l-9.017-9.017c-6.25-6.25-16.383-6.25-22.632-.001l-14.517 14.517c-6.25 6.25-6.25 16.383 0 22.632l9.018 9.018c3.043 3.043 3.724 7.661 2.074 11.635-1.649 3.973-5.412 6.804-9.713 6.804H2.259c-8.838 0-16.003 7.165-16.003 16.003v20.53c0 8.838 7.165 16.003 16.003 16.003h12.752c4.3 0 8.064 2.831 9.713 6.804 1.65 3.975.968 8.593-2.075 11.636l-9.017 9.017c-6.25 6.25-6.25 16.383 0 22.632l14.517 14.517c6.25 6.25 16.382 6.25 22.632 0l9.018-9.018c3.043-3.043 7.66-3.724 11.635-2.074 3.973 1.649 6.804 5.412 6.804 9.713v12.752c0 8.838 7.165 16.003 16.003 16.003h20.53c8.838 0 16.003-7.165 16.003-16.003v-12.752c0-4.301 2.831-8.064 6.804-9.713 3.975-1.65 8.593-.968 11.635 2.074l9.018 9.018c6.249 6.249 16.382 6.249 22.632 0l14.516-14.517c6.25-6.249 6.25-16.382 0-22.632l-9.017-9.017c-3.043-3.043-3.724-7.661-2.074-11.636 1.648-3.973 5.412-6.804 9.713-6.804h12.752c8.838 0 16.003-7.165 16.003-16.003v-20.53c0-8.838-7.165-16.003-16.003-16.003h-12.752c-4.301 0-8.065-2.831-9.713-6.804-1.65-3.975-.969-8.592 2.074-11.635l9.017-9.018c6.25-6.249 6.25-16.382 0-22.632l-14.516-14.516c-6.25-6.25-16.383-6.25-22.632 0l-9.018 9.017c-3.042 3.043-7.66 3.724-11.635 2.074-3.973-1.648-6.804-5.412-6.804-9.713V116.97c0-8.839-7.165-16.004-16.003-16.004h-20.53zm10.265 157.292c21.562 0 39.042-17.48 39.042-39.042s-17.48-39.042-39.042-39.042-39.042 17.48-39.042 39.042 17.48 39.042 39.042 39.042z"
            clipRule="evenodd"
          ></path>
        </g>
        <g filter="url(#filter4_d_24_598)">
          <path
            stroke="#800062"
            strokeWidth="28.444"
            d="M146.016-62.478c-20.147 20.147-48.352 67.513 0 95.805C206.457 68.69 275.899 10.179 274.613 89.91c-1.286 79.73 34.079 73.943 77.159 72.014"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_24_598"
          width="302.008"
          height="303.512"
          x="-44.721"
          y="73.915"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_598"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_598" result="shape"></feBlend>
        </filter>
        <filter
          id="filter1_d_24_598"
          width="120.943"
          height="338.274"
          x="-7.702"
          y="-11.806"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_598"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_598" result="shape"></feBlend>
        </filter>
        <filter
          id="filter2_d_24_598"
          width="153.199"
          height="355.494"
          x="188.485"
          y="-11.806"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_598"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_598" result="shape"></feBlend>
        </filter>
        <filter
          id="filter3_d_24_598"
          width="240.056"
          height="240.056"
          x="-13.744"
          y="100.966"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_598"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_598" result="shape"></feBlend>
        </filter>
        <filter
          id="filter4_d_24_598"
          width="250.866"
          height="253.14"
          x="105.097"
          y="-72.535"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_598"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_598" result="shape"></feBlend>
        </filter>
        <clipPath id="clip0_24_598">
          <path
            fill="#fff"
            d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Evolution;
