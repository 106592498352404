/** @jsxImportSource @emotion/react */
import WaveTop from "../icons/WaveTop";
import { css } from "@emotion/react";
import { colors } from "../theme";
import WaveBottom from "../icons/WaveBottom";
import CurveOne from "../icons/CurveOne";
import CurveThird from "../icons/CurveThird";
import CurveSecond from "../icons/CurveSecond";
import ProductDesign from "./ProductDesign";
import WaveTopMobile from "../icons/WaveTopMobile";
import WaveBottomMobile from "../icons/WaveBottomMobile";
import useWindowWidth from "../utils/useWidth";

const WaveSection = (props) => {
  const isMobile = useWindowWidth();

  const TestItems = [
    {
      curve: CurveOne,
      // icon: tool,
      title: props.isAds ? "Maximize your ROI" : "Interface Design",
      text: props.isAds
        ? "Based on your strategy, we will help you achieve maximum results from your campaigns. Stop losing money on fraud, untargeted ads, and expensive publishers!"
        : "We help you create a customized design while enhancing the native Telegram user experience.",
      icon: props.isAds ? "./img/roi.png" : "./img/tool.png",
    },
    {
      curve: CurveSecond,
      // icon: developing,
      title: props.isAds ? "Segmentation" : "Developing",
      text: props.isAds
        ? "Don't spend your entire advertising budget on a single segment! We will help you diversify your ads on Telegram and A/B test strategies to find what works best for you!"
        : "Design alone isn’t enough; the right development skills are essential. Our team will help you build Mini Apps using the latest methods and tools.",
      icon: props.isAds ? "./img/Segmentation.png" : "./img/developing.png",
    },
    {
      curve: CurveThird,
      // icon: marketing,
      title: props.isAds ? "Campaign Strategy " : "Marketing",
      text: props.isAds
        ? "Advertising on Telegram is not simple. We will help you strategize your goals and create an ad strategy with multiple segments that fit your needs."
        : "You can rely on our experts to run campaigns that bring your first customers to your new product.",
      icon: props.isAds ? "./img/Campaign Strategy.png" : "./img/marketing.png",
    },
  ];

  return (
    <div
      css={css({
        maxWidth: "100%",
        "@media (max-width:768px)": {
          marginTop: props.isAds ? "10rem" : "",
        },
      })}
    >
      {isMobile ? (
        <WaveTopMobile
          css={css({
            marginBottom: "-1rem",
            width: "100%",
          })}
        />
      ) : (
        <WaveTop
          css={css({
            maxWidth: "100%",
            marginBottom: "-0.5rem",
          })}
        />
      )}

      <div
        css={css({
          backgroundColor: colors.primary,
          "@media (max-width:768px)": {
            height: "123rem",
          },
          "@media (max-width:990px)": {
            height: props.isAds ? "135rem" : "126rem",
          },
          "@media (min-width: 990px) and (max-width: 1280px)": {
            height: "47rem",
          },
          "@media (min-width: 1280px) and (max-width: 1440px)": {
            height: "49rem",
          },
          "@media (min-width: 1440px) and (max-width: 1880px)": {
            height: "51rem",
          },
        })}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "1rem",
          }}
        >
          <div
            style={{
              maxWidth: "1440px",
            }}
          >
            <div
              id="title"
              css={css({
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                color: colors.whiteText,
                "@media (max-width:990px)": {
                  textAlign: "center",
                  marginLeft: "2rem",
                  marginRight: "2rem",
                  gap: "2rem",
                },
              })}
            >
              <h1
                css={css({
                  fontSize: "52px",
                  fontWeight: "normal",
                  "@media (max-width:990px)": {
                    fontSize: "32px",
                    lineHeight: "1.5",
                  },
                })}
              >
                {props.isAds ? "How can we help you?" : "Build your idea now!"}
              </h1>
              <h3
                css={css({
                  fontSize: "26px",
                  fontWeight: "bolder",
                  fontFamily: "Noto Sans",
                  "@media (max-width:990px)": {
                    fontSize: "26px",
                  },
                })}
              >
                {props.isAds
                  ? "You can reach more than ever with us"
                  : " We are here to help in every step through the journey"}
              </h3>
            </div>

            <div
              css={css({
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: "2rem",
                gap: "6rem",
                "@media (max-width:990px)": {
                  flexDirection: "column",
                  gap: "4rem",
                  alignItems: "center",
                },
              })}
            >
              {TestItems.map((item, index) => (
                <ProductDesign key={index} {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
      {isMobile ? (
        <WaveBottomMobile
          css={css({
            width: "100%",
            marginTop: "-1rem",
            zIndex: "2",
            position: "relative",
          })}
        />
      ) : (
        <WaveBottom
          css={css({
            maxWidth: "100%",
            marginTop: "-1rem",
            zIndex: "2",
            position: "relative",
            width: "100%",
            height: "100%",
            "@media (min-width: 320px) and (max-width: 768px)": {
              marginBottom: "-7rem",
            },
            "@media (min-width: 768px) and (max-width: 1024px)": {
              marginBottom: "8.5rem",
            },
            "@media (min-width: 1024px) and (max-width: 1280px)": {
              marginBottom: "8.5rem",
            },
            "@media (min-width: 1080px) and (max-width: 1280px)": {
              marginBottom: "7.5rem",
            },
            "@media (min-width: 1280px) and (max-width: 1536px)": {
              marginBottom: "5rem",
            },
            "@media (min-width:1536px)": {
              marginBottom: "2.5rem",
            },
          })}
        />
      )}
    </div>
  );
};
export default WaveSection;
