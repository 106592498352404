/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "../App.css";
import { colors } from "../theme";
import Nav from "./Nav";
import ServicesIcon from "../icons/services";
import Contacts from "../icons/contacts";
import Meet from "../icons/Meet";
import { handleCalendry } from "../utils/Calendry";
import Home from "../icons/Home";

const Navbar = () => {
  return (
    <div
      css={css({
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.dark,
        width: "88px",
        height: "312px",
        top: "50%",
        transform: "translateY(-50%)",
        paddingTop: "2rem",
        paddingBottom: "2rem",
        marginLeft: "35px",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        borderRadius: "40px",
        left: "0",
        zIndex: "99",
        boxShadow: "1px 2px 9px rgb(48, 48, 47)",
        "@media (max-width:768px)": {
          width: "80%",
          height: "80px",
          marginLeft: "0",
          marginTop: "10px",
          paddingTop: "0.2rem",
          paddingBottom: "0.8rem",
          paddingRight: "28px",
          paddingLeft: "28px",
          bottom: "40px",
          gap: "20px",
          left: "50%",
          top: "auto",
          transform: "translateX(-50%)",
          flexDirection: "row",
          position: "fixed",
        },
      })}
    >
      <Nav
        icon={window.location.pathname === "/" ? <ServicesIcon /> : <Home />}
        title={window.location.pathname === "/" ? "Services" : "Home"}
        link={window.location.pathname === "/" ? "services" : ""}
        onClick={() => {
          if (window.location.pathname !== "/") {
            window.location = "/";
          }
        }}
      />

      <Nav icon={<Contacts />} title="Contact" link="contacts" />
      <Nav icon={<Meet />} title="Meeting" onClick={handleCalendry()} lastItem />
    </div>
  );
};
export default Navbar;
