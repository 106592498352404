import * as React from "react";

const Easy = (props) => (
  <svg
    width={92}
    height={92}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_1097_1428)">
      <path
        d="M65.3568 37.3392C67.6172 36.5858 70.4571 37.8608 71.1526 40.8166C71.8481 43.7724 69.7037 45.627 69.1241 46.2645C68.5445 46.902 63.4205 52.246 62.8068 52.8136C60.4886 54.9579 53.5337 55.3057 54.8088 49.1044M15.0742 69.9213C17.8233 66.7455 23.2931 59.7399 23.1794 57.1234C23.0373 53.8528 21.8997 46.5483 21.8997 42.9035C21.8997 38.0052 16.4961 31.101 18.0603 28.5414C19.6245 25.9818 28.8674 23.28 31.8536 31.6698C32.8029 34.337 33.5681 36.4018 34.1653 37.9602C34.9069 39.8956 37.1908 40.2532 38.5122 38.6564C46.0661 29.5284 65.268 6.32664 71.3696 13.0595C74.3312 16.3276 63.7173 25.8679 63.1155 26.5103C62.5138 27.1526 48.8272 41.1842 47.8494 43.8167C46.8716 46.4492 48.3391 55.8789 42.9237 61.2191M61.5614 28.7714C64.2541 27.75 67.6348 31.662 66.7991 34.262C65.9635 36.862 55.4592 48.1952 54.5769 48.9305C52.3483 50.7876 48.028 48.5305 47.5637 46.6734M70.104 45.7621C73.0091 44.5517 75.8772 44.573 76.5227 48.5272C77.1682 52.4814 70.3772 58.3587 68.5212 59.8113C66.6651 61.2639 60.3394 60.0457 62.2762 53.5898M67.8039 60.8784C58.7534 70.4462 54.0283 74.973 47.5637 76.3953C42.3921 77.5331 40.0505 83.2885 38.5122 84.9444M24.5547 11.8755L30.8435 18.0214M49.3126 11.8755L43.0238 18.0214M35.9891 4.44324V13.4477"
        stroke="#F2F8FA"
        strokeOpacity={0.01}
        strokeWidth={4}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1097_1428"
        x={0}
        y={0}
        width={93}
        height={94}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={2} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1097_1428" />
      </filter>
    </defs>
  </svg>
);

export default Easy;
