import * as React from "react";

const WaveBottom = (props) => (
  <svg viewBox="0 0 1641 177" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M84.1583 116.263L77.5816 113.254C27.4964 90.3426 -12.6817 50.187 -35.6215 0.11467H1676.62L1655 12.7821C1547.68 75.6498 1422.65 101.431 1299.22 86.1431L1165.54 69.5849C1099.22 61.3715 1032.41 80.3352 980.298 122.16C918.422 171.823 836.479 188.79 759.97 167.783L518.049 101.358C449.343 82.4929 376.454 85.6105 309.61 110.273L273.931 123.437C212.21 146.21 143.984 143.63 84.1583 116.263Z"
      fill="#006180"
    />
  </svg>
);

export default WaveBottom;
