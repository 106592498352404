import React from "react";

function Deal(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 640 421" {...props}>
      <g clipPath="url(#clip0_64_1040)">
        <path
          fill="#006D8F"
          d="M639.861 298.369c-.634 12.996-3.806 25.719-9.323 37.38-8.996 18.714-22.995 34.003-39.565 45.825a157.606 157.606 0 01-29.459 16.461 173.102 173.102 0 01-17.401 6.379c-35.736 10.952-73.445 11.489-110.641 11.945l-261.801 3.206-34.431.415c-10.953.138-22.117.219-33.164-.415-21.076-1.188-41.764-4.956-59.903-15.802-9.341-5.623-17.497-13.171-23.974-22.189C7.9 364.577 1.25 342.901.933 321.298v-2.27c.132-21.083 6.347-41.921 19.273-58.105a76.588 76.588 0 012.54-3.026c1.826-2.067 3.823-4.069 5.92-6.07 13.355-12.62 30.99-23.93 38.983-40.188 14.061-28.608-3.472-40.284-5.78-68.348-3.884-47.193 41.733-101.424 78.79-123.075C180.496-3.12 230.681-5.92 272.678 12.803c44.436 19.821 85.905 62.986 132.781 50.814 15.071-3.914 28.914-13.548 44.444-13.833 28.697-.577 48.616 29.992 57.55 58.585 8.934 28.592 13.781 60.862 35.02 81.098 15.242 14.516 36.256 19.976 54.715 29.618a89.66 89.66 0 0115.84 10.293c10.876 8.999 18.349 21.212 22.653 34.784a97.2 97.2 0 014.18 34.207z"
          opacity="0.18"
        ></path>
        <path
          fill="#006D8F"
          d="M591.136 381.843c-14.395 10.111-30.204 17.813-46.861 22.831-33.404 10.244-68.526 11.392-103.322 11.847l-7.295.098-96.113 1.18-48.817.602-108.66 1.326-8.242.098-34.438.423c-10.954.138-22.11.212-33.164-.423-21.1-1.156-41.788-4.923-59.943-15.777-9.31-5.636-17.435-13.19-23.88-22.205h570.735z"
          opacity="0.18"
        ></path>
        <path
          fill="#000405"
          d="M485.203 400.118c.225 3.393-2.074 6.672-1.422 10 .964 4.882 6.285 4.069 11.055 3.703 11.591-.993 26.413-2.848 37.701-5.9 10.449-2.831 18.054-5.565 28.014-8.95.776-.268.536-2.579.574-3.434.063-1.131-1.367-1.554-2.447-1.627-9.85-.334-19.81 0-29.474-2.034-4.342-.928-8.6-2.344-13.004-2.872-5.438-.643-10.876.057-16.314-.375-3.939-.317-13.533-4.833-17.192-2.978-5.485 2.84 2.237 10.366 2.509 14.467zm-228.233 3.06c11.38 3.344 20.586 10.423 30.608 15.907-36.223.428-72.442.868-108.66 1.318a49.889 49.889 0 0110.1-7.795c11.909-6.981 25.9-8.592 39.464-10.073 9.478-1.042 19.274-2.059 28.488.643zm184.729 10.024a6.16 6.16 0 01-1.29 2.946 5.315 5.315 0 00-.606 1.057l-7.294.098-96.129 1.155a29.163 29.163 0 004.708-4.841c2.004-2.604 3.604-5.557 5.935-7.819 6.883-6.689 17.782-5.444 26.701-2.441 2.711.927 5.438 1.985 8.297 1.944 5.065-.065 9.415-3.515 13.983-5.891 14.349-7.502 33.343-3.482 43.784 9.268 1.025 1.318 2.043 2.848 1.911 4.524z"
          opacity="0.05"
        ></path>
        <path
          fill="#25233A"
          d="M390.426 299.158l-17.766 91.514-11.941-1.432-1.375-.171s3.418-27.128 4.894-65.818c.972-25.631 5.159-46.558 7.163-63.955a82.083 82.083 0 013.108-14.109c2.423-7.697 6.113-14.898 10.806-14.157 10.363 1.627 24.012 11.009 25.815 16.883 3.138 10.252-20.704 51.245-20.704 51.245z"
        ></path>
        <path
          fill="#fff"
          d="M378.004 245.447s-7.963 64.59-8.219 84.744c-.256 20.155-8.172 58.877-8.172 58.877l-.917.171-1.375-.171s3.418-27.127 4.894-65.817c.971-25.631 5.158-46.559 7.163-63.955a81.946 81.946 0 013.107-14.109c1.179-.055 2.36.032 3.519.26z"
          opacity="0.24"
        ></path>
        <path
          fill="#3F3D56"
          d="M431.157 390.525h-13.168a.561.561 0 010-.082c-.357-1.863-6.991-36.281-16.368-61.212-6.992-18.592-10.371-56.461-11.653-75.842-.513-7.323-.715-12.01-.715-12.01s26.818-8.291 30.298-4.613c3.48 3.677 7.434 20.341 7.434 20.341s-9.182 65.989-3.34 70.464c5.842 4.475 7.512 62.954 7.512 62.954z"
        ></path>
        <path
          fill="#006D8F"
          d="M222.182 126.782s-19.531 6.306-27.439 65.346c-2.859 21.286 15.32 58.885 15.32 58.885l12.119-124.231z"
        ></path>
        <path
          fill="url(#paint0_linear_64_1040)"
          d="M222.182 126.782s-19.531 6.306-27.439 65.346c-2.859 21.286 15.32 58.885 15.32 58.885l12.119-124.231z"
          opacity="0.61"
        ></path>
        <path
          fill="#25233A"
          d="M207.919 243.641a7.285 7.285 0 00-3.581.279 7.576 7.576 0 00-3.062 1.965 8.055 8.055 0 00-1.862 3.215 8.326 8.326 0 00-.25 3.752c1.491 9.227 4.334 22.034 9.322 29.292a9680.33 9680.33 0 0119.911 28.91l11.886 85.435 14.659-2.075-.132-81.871-16.547-63.654-30.344-5.248z"
        ></path>
        <path
          fill="#3F3D56"
          d="M238.224 248.889l-1.903 71.115-31.424 76.542-10.527-4.695-3.06-1.375 17.401-71.766s-6.774-19.528-11.761-33.767c-4.786-13.662 1.724-40.732 2.268-42.913v-.146l39.006 7.005z"
        ></path>
        <path
          fill="#006D8F"
          d="M368.348 203.405l-38.773 20.13-7.24-9.658-1.857-2.49 33.125-18.836s11.381-26.502 13.852-38.682a79.37 79.37 0 011.39-5.623c.863-2.823 9.09-12.799 11.855-13.393 2.766-.594-.349 8.308 1.492 10.578 2.021 3.113 3.054 6.814 2.952 10.578.318 7.551-16.796 47.396-16.796 47.396z"
        ></path>
        <path
          fill="#006D8F"
          d="M419.504 226.977s-19.258 24.239-41.461 18.47a29.321 29.321 0 01-4.475-1.578 7.784 7.784 0 01-2.507-1.809 8.213 8.213 0 01-1.651-2.679 8.483 8.483 0 01.111-6.253c2.144-5.093 2.206-12.457 2.012-19.251-.451-15.631-4.226-32.547-4.506-39.585-.186-4.5.505-10.871 1.771-17.234 2.23-11.31 6.254-22.579 10.34-23.23 12.15-1.961 16.314 1.505 16.314 1.505l20.743 12.62c10.301 11.311 5.282 29.016 3.309 79.024z"
        ></path>
        <path
          fill="#006D8F"
          d="M389.059 163.137l5.306 8.95-14.69 15.565-3.022 3.206 15.964-56.672.062-5.671 2.028 2.172v.009l2.657 2.847 1.018 23.467-9.323 6.127z"
        ></path>
        <path
          fill="url(#paint1_linear_64_1040)"
          d="M417.531 176.505l-2.253 61.099s-31.696 15.777-35.883-1.953c-3.239-13.702 5.05-29.333 5.05-29.333l33.086-29.813z"
          opacity="0.61"
        ></path>
        <path
          fill="#fff"
          d="M386.317 210.386l-9.532 40.204 58.901 12.661 8.468-39.333-57.837-13.532z"
        ></path>
        <path
          fill="url(#paint2_linear_64_1040)"
          d="M393.091 119.548c0 .179-.21 9.284-.513 15.078-.217 4.483-2.221 7.64-5.282 10.764a18.899 18.899 0 01-6.285 4.541 3.18 3.18 0 01-1.792.149 3.267 3.267 0 01-1.604-.849 3.518 3.518 0 01-.948-1.6 3.643 3.643 0 01-.014-1.883c.303-1.204.606-2.603.839-4.068.497-3.019.715-6.29-.07-8.6a1.85 1.85 0 00-.078-.253c-.466-1.196 0-2.498 1.057-3.808 1.484-1.847 4.078-3.718 6.665-5.297a78.288 78.288 0 018.025-4.174z"
        ></path>
        <path
          fill="url(#paint3_linear_64_1040)"
          d="M379.185 141.07a6.855 6.855 0 01-1.678.618c.497-3.019.715-6.29-.07-8.6l.979-4.069.738-3.027 5.927-2.27 6.005-2.294c-1.445 11.196-7.162 17.29-11.901 19.642z"
        ></path>
        <path
          fill="url(#paint4_linear_64_1040)"
          d="M377.554 87.734a16.5 16.5 0 00-6.744 1.935 17.263 17.263 0 00-5.44 4.601 18.213 18.213 0 00-3.225 6.499 18.71 18.71 0 00-.47 7.307 62.698 62.698 0 005.298 20.976c5.586 9.927 19.616 1.709 21.83-2.392 2.214-4.101 10.565-22.653 7.038-27.917-2.33-3.645-9.182-11.448-18.287-11.01z"
        ></path>
        <path
          fill="#3F3D56"
          d="M362.7 80.737l2.89 1.546a10.465 10.465 0 00-4.977 1.145 10.99 10.99 0 00-3.933 3.395c-2.64 2.774-4.133 6.523-4.157 10.44l4.902-2.442s-.365 6.339 8.927 6.803c9.291.463 19.126.813 19.126.813l-3.154-4.564s5.78 2.441 6.79 4.646a76.355 76.355 0 011.988 10.919s2.292-6.257 3.248-4.882c.955 1.375-.995 7.038-2.199 8.283-1.204 1.245-1.002 4.972-1.111 5.281-.109.309 5.757-4.809 6.013-6.566.256-1.758 6.067-19.529 2.253-23.239l2.33-.187a10.586 10.586 0 00-4.56-1.839s-15.304-20.895-34.376-9.552z"
        ></path>
        <path
          fill="url(#paint5_linear_64_1040)"
          d="M302.307 219.719a45.072 45.072 0 018.289-6.013 26.248 26.248 0 017.217-1.562s.404 3.556-1.553 3.792c-1.958.236-3.589 3.352-3.589 3.352s12.561 5.387 14.294 4.882c1.732-.504 1.39 2.116-1.787 1.945-3.177-.171-3.954 4.483-6.153 4.418-2.198-.065-5.679 1.359-8.91 1.481a32.34 32.34 0 01-9.525-2.327l1.717-9.968z"
        ></path>
        <path
          fill="#006D8F"
          d="M253.691 181.933l-10.534 62.042s-3.636 11.871-22.451 10.098c-6.884-.61-13.359-3.678-18.342-8.69l-.303-.301-.209-.228c-3.604-3.734-6.427-8.212-8.29-13.149 0 0 12.088-87.453 24.782-102.677 1.267-1.514 2.533-2.319 3.807-2.246a21.871 21.871 0 019.695 3.89c8.143 5.249 14.585 12.95 18.482 22.096 3.898 9.146 5.07 19.31 3.363 29.165z"
        ></path>
        <path
          fill="url(#paint6_linear_64_1040)"
          d="M233.889 179.882l-13.206 74.182s20.237 2.913 22.451-10.089l6.238-37.73-15.483-26.363z"
          opacity="0.61"
        ></path>
        <path
          fill="url(#paint7_linear_64_1040)"
          d="M222.182 126.782s2.012-15.956 2.82-26.331c.808-10.374 4.04-24.271 20.665-22.49 16.624 1.782 16.679 28.105 12.088 39.122-4.592 11.017-10.737 10.7-10.737 10.7s-8.281-1.888-7.97 8.543l-16.866-9.544z"
        ></path>
        <path
          fill="#3F3D56"
          d="M228.32 75.757c-2.421 2.436-4.168 5.511-5.058 8.901a48.87 48.87 0 00-1.507 8.405l1.694-1.944a135.937 135.937 0 01-1.034 14.556 82.758 82.758 0 00-.776 13.589l3.107-.163 3.884 4.996 4.289-3.043 3.223-1.799 2.875-7.437a4.185 4.185 0 01-1.489-2.192 4.377 4.377 0 01.091-2.69c1.056-3.035 3.682-3.734 4.296-1.944.468 2.23.728 4.503.776 6.786l4.584-13.597s2.874-4.223 5.391-1.318a13.456 13.456 0 005.547 3.873l-1.515-4.491 4.156 1.114-.326-5.19 3.108 2.27s1.359-8.406-1.275-12.132c-2.633-3.727-13.121-18.609-34.041-6.55z"
        ></path>
        <path
          fill="#006D8F"
          d="M302.657 218.035l-2.105 11.652-.567 3.132-37.919-2.961c-10.495-.944-20.796-8.463-22.101-19.455l-13.122-48.519a12.883 12.883 0 01-.29-6.144 12.558 12.558 0 012.582-5.526 11.776 11.776 0 014.811-3.528 11.26 11.26 0 015.84-.653 13.22 13.22 0 016.765 3.222 14.197 14.197 0 014.111 6.485l8.258 43.426a13.762 13.762 0 003.387 6.768 12.791 12.791 0 006.347 3.769l34.003 8.332z"
        ></path>
        <path
          fill="url(#paint8_linear_64_1040)"
          d="M322.335 213.869l-5.283 1.725s-6.603.618-8.802 2.588c-2.198 1.969-.971 2.856-.971 2.856s6.992-1.074 8.499-.464c1.507.61 8.879 3.678 11.21 3.637 2.331-.041 2.587-.675 2.587-.675l-7.24-9.667z"
        ></path>
        <path
          fill="url(#paint9_linear_64_1040)"
          d="M306.401 228.361a3.295 3.295 0 011.357-.767 3.19 3.19 0 011.541-.047c.114.02.22.077.303.163a.713.713 0 010 .642 11.15 11.15 0 01-1.647 3.442c.823-.293 1.204-1.253 1.725-1.977a4.412 4.412 0 011.474-1.3 4.214 4.214 0 011.866-.506.752.752 0 01.451.097c.318.228.178.749 0 1.115-.327.683-.645 1.375-.971 2.067a19.637 19.637 0 012.843-3.418c.253-.287.575-.498.932-.61a.745.745 0 01.543.033c.171.079.31.221.389.398.066.217.066.45 0 .668a7.773 7.773 0 01-1.165 2.611 12.5 12.5 0 012.439-3.018.658.658 0 01.252-.19.626.626 0 01.308-.046.482.482 0 01.272.244c.326.569 0 1.294-.342 1.863-.629 1.05-1.43 2.173-2.603 2.36a2.907 2.907 0 00-.777.146 2.76 2.76 0 00-.505.35 2.439 2.439 0 01-1.369.464 2.43 2.43 0 01-1.388-.399 3.315 3.315 0 01-1.897.56 3.332 3.332 0 01-1.879-.625.711.711 0 00-.932-.073 2.47 2.47 0 01-1.564.033 2.577 2.577 0 01-1.295-.92c-.699-1.066.87-2.685 1.639-3.36z"
        ></path>
        <path
          fill="#006D8F"
          d="M420.235 248.588l-12.974-4.695-.443-.163 9.455-31.83s-12.43-35.761-14.497-46.762a32.21 32.21 0 01-.093-11.041 9.732 9.732 0 012.426-5.044 9.017 9.017 0 014.721-2.727 8.482 8.482 0 015.951.814 39.25 39.25 0 014.661 2.978 6.527 6.527 0 012.385 3.726c2.331 10.025 11.707 50.92 12.057 54.801.381 4.451-13.649 39.943-13.649 39.943z"
        ></path>
        <path
          fill="url(#paint10_linear_64_1040)"
          d="M403.431 246.595c-.637.635-1.243 1.774-.567 2.376.163.146.388.228.52.407.327.431-.077 1.033-.45 1.415l-3.108 3.166c-1.149 1.163-2.4 2.62-2.136 4.271.127.588.404 1.129.8 1.566.397.437.899.753 1.453.916a6.17 6.17 0 003.395-.041c-.019.343.058.683.223.98.164.296.408.536.701.688.595.294 1.239.46 1.896.489a13.51 13.51 0 005.71-.163c.139.22.279.431.427.643 2.455-.456 3.791-3.206 4.661-5.647.87-2.441 1.756-5.159 2.502-7.787a1.84 1.84 0 00.07-1.115 1.776 1.776 0 00-.465-.556 1.676 1.676 0 00-.639-.314c-2.967-1.18-5.927-2.384-8.902-3.548-2.751-1.074-4.001.212-6.091 2.254z"
        ></path>
        <path
          fill="url(#paint11_linear_64_1040)"
          d="M193.298 391.119l-4.466 8.511 4.49 2.693 1.67-1.4 7.24 6.982s2.587 1.863 8.919 1.847c6.331-.017 7.582-.952 7.582-.952s0-9.764-7.769-9.317c-7.768.448-8.304-4.182-8.304-4.182l-9.362-4.182z"
        ></path>
        <path
          fill="url(#paint12_linear_64_1040)"
          d="M242.722 396.228l-.73 12.58h5.438v-4.752l7.38 4.719 18.248-.049s0-13.018-8.203-12.335c-8.204.684-11.513 0-11.513 0l-1.298-1.814-9.322 1.651z"
        ></path>
        <path
          fill="url(#paint13_linear_64_1040)"
          d="M430.427 390.492l.777 12.571h-5.438v-4.751l-7.326 4.768-18.249.048s0-13.018 8.15-12.375c8.149.642 11.513 0 11.513 0l1.281-1.823 9.292 1.562z"
        ></path>
        <path
          fill="url(#paint14_linear_64_1040)"
          d="M370.943 390.647l.776 12.563h-5.438v-4.752l-7.372 4.784-18.248.049s0-13.018 8.149-12.376c8.149.643 11.513 0 11.513 0l1.282-1.822 9.338 1.554z"
        ></path>
        <path
          fill="#006D8F"
          d="M389.059 163.137l5.306 8.95-14.69 15.565-3.022 3.206 15.964-56.672.062-5.671 2.028 2.172v.009l2.657 2.847 1.018 23.467-9.323 6.127z"
        ></path>
        <path
          fill="#fff"
          d="M377.507 141.688l-1.981 9.764 1.127 39.398 14.473-51.367-6.215 4.117c-.334.22-.676.423-1.033.611a4.439 4.439 0 01-1.835.508 4.428 4.428 0 01-1.874-.311 4.581 4.581 0 01-1.594-1.077 4.829 4.829 0 01-1.044-1.659l-.024.016z"
        ></path>
        <path
          fill="#006D8F"
          d="M377.6 133.82l-6.455 12.669 2.144 5.289-3.504 7.933 6.844 31.139s-1.872-38.063.855-49.178c.023.016.924-5.305.116-7.852z"
        ></path>
        <path
          fill="url(#paint15_linear_64_1040)"
          d="M389.059 163.137l5.306 8.95-14.69 15.565-3.022 3.206 15.964-56.672.062-5.671 2.028 2.172v.009l2.657 2.847 1.018 23.467-9.323 6.127z"
          opacity="0.61"
        ></path>
        <path
          fill="url(#paint16_linear_64_1040)"
          d="M377.6 133.82l-6.455 12.669 2.144 5.289-3.504 7.933 6.844 31.139s-1.872-38.063.855-49.178c.023.017.924-5.305.116-7.852z"
          opacity="0.61"
        ></path>
        <path
          fill="#006D8F"
          d="M221.654 124.113l19.072 9.935 5.158 10.472-27.524-15.509 3.294-4.898z"
        ></path>
        <path
          fill="url(#paint17_linear_64_1040)"
          d="M221.654 124.113l19.072 9.935 5.158 10.472-27.524-15.509 3.294-4.898z"
          opacity="0.61"
        ></path>
        <path
          fill="#006D8F"
          d="M170.676 261.867v158.666h-16.679v-71.977H3.551A111.19 111.19 0 010 322.25v-2.246c.132-21.074 6.339-41.92 19.273-58.104l151.402-.033zm17.798-9.097v9.097h-169.2a77.248 77.248 0 012.533-3.027c1.833-2.066 3.822-4.068 5.927-6.07h160.74zm-92.026-52.375c-1.911-1.921-4.195-3.385-6.215-5.143-.715-.618-1.469-1.448-1.329-2.441.09-.384.285-.733.56-1.003.276-.27.622-.451.994-.518a5.463 5.463 0 012.26.146l-4.35-5.126c-.404-.48-.839-1.139-.544-1.701.296-.561 1.127-.529 1.702-.244a3.49 3.49 0 001.755.659c-1.553-2.286-3.527-4.149-5.181-6.322-1.655-2.172-2.983-4.882-2.735-7.616.004-.398.139-.783.38-1.09.498-.521 1.337-.301 1.982 0 4.602 2.098 8.294 5.915 10.355 10.708a20.324 20.324 0 01-.854-7.234c-.016-.285.029-.57.131-.834.102-.265.259-.502.46-.695.776-.562 1.802.406 2.252 1.31 3.061 6.24.925 13.832 2.175 20.74.164-1.921 1.019-3.704 2.393-4.988.38 3.556.249 7.152-.388 10.667.955.44.878 1.872.637 2.946a51.183 51.183 0 01-1.166 4.882c-1.709-.147-1.74-1.123-2.276-2.563a12.906 12.906 0 00-2.998-4.54z"
        ></path>
        <path
          fill="#006D8F"
          d="M110.143 179.126c1.608-1.913 3.535-3.727 5.92-4.248.722 1.457 0 3.255-1.002 4.532-1.002 1.278-2.245 2.441-2.75 3.955 2.08-2.602 4.896-4.445 8.04-5.265a4.356 4.356 0 013.403.204 1.328 1.328 0 01.637.813c.186.895-.901 1.432-1.756 1.628-5.996 1.655-11.259 5.436-14.892 10.699 3.545-2.244 7.699-3.197 11.816-2.709.714.089 1.553.317 1.818 1.009.411 1.025-.7 2.002-1.709 2.351-3.885 1.351-8.748-.089-11.886 2.694a6.495 6.495 0 014.769 1.383c1.096.871 1.904 2.652.94 3.678a2.73 2.73 0 01-1.106.55 2.664 2.664 0 01-1.224.003c-2.245-.203-5.011-.594-6.3 1.343-.707 1.065-.653 2.441-.917 3.742-.21 1.034-1.026 2.865-2.098 1.856-.714-.676-.435-3.035-.435-3.93.053-8.918 3.144-17.522 8.717-24.264l.015-.024z"
        ></path>
        <path
          fill="url(#paint18_linear_64_1040)"
          d="M96.447 200.395c-1.91-1.921-4.195-3.385-6.215-5.143-.714-.618-1.468-1.448-1.328-2.441.09-.384.284-.733.56-1.003.276-.27.621-.451.994-.518a5.464 5.464 0 012.26.146l-4.35-5.126c-.404-.48-.839-1.139-.544-1.701.295-.561 1.127-.529 1.701-.244a3.49 3.49 0 001.756.659c-1.553-2.286-3.527-4.149-5.181-6.322-1.655-2.172-2.984-4.882-2.735-7.616.004-.398.138-.783.38-1.09.498-.521 1.337-.301 1.982 0 4.602 2.098 8.293 5.915 10.355 10.708a20.327 20.327 0 01-.854-7.234c-.016-.285.029-.57.13-.834.103-.265.26-.502.46-.695.777-.562 1.802.406 2.253 1.31 3.061 6.24.924 13.832 2.175 20.74.163-1.921 1.019-3.704 2.393-4.988.38 3.556.249 7.152-.389 10.667.956.44.878 1.872.637 2.946a50.877 50.877 0 01-1.165 4.882c-1.709-.147-1.74-1.123-2.276-2.563a12.906 12.906 0 00-2.999-4.54z"
          opacity="0.61"
        ></path>
        <path
          fill="url(#paint19_linear_64_1040)"
          d="M110.143 179.126c1.608-1.913 3.535-3.727 5.92-4.248.722 1.457 0 3.255-1.002 4.532-1.002 1.278-2.245 2.441-2.75 3.955 2.08-2.602 4.896-4.445 8.04-5.265a4.356 4.356 0 013.403.204 1.328 1.328 0 01.637.813c.186.895-.901 1.432-1.756 1.628-5.996 1.655-11.259 5.436-14.892 10.699 3.545-2.244 7.699-3.197 11.816-2.709.714.089 1.553.317 1.818 1.009.411 1.025-.7 2.002-1.709 2.351-3.885 1.351-8.748-.089-11.886 2.694a6.495 6.495 0 014.769 1.383c1.096.871 1.904 2.652.94 3.678a2.743 2.743 0 01-1.106.55 2.664 2.664 0 01-1.224.003c-2.245-.203-5.011-.594-6.3 1.343-.707 1.065-.653 2.441-.917 3.742-.21 1.034-1.026 2.865-2.098 1.856-.714-.676-.435-3.035-.435-3.93.053-8.918 3.144-17.522 8.717-24.264l.015-.024z"
          opacity="0.61"
        ></path>
        <path
          fill="#fff"
          d="M111.775 252.681l-8.888.049-8.794.041c-19.343-12.051 2.976-36.477 2.976-36.477s.885-3.808-.272-6.208a3.29 3.29 0 00-1.204-1.408c-2.851-1.79 1.126-2.498 1.126-2.498h11.933s4.086.358 1.017 2.612c-3.068 2.254-1.359 7.502-1.359 7.502 23.912 27.803 3.465 36.387 3.465 36.387z"
        ></path>
        <path
          fill="url(#paint20_linear_64_1040)"
          d="M103.532 251.997a6.173 6.173 0 01-.645.733l-8.794.04c-19.343-12.05 2.976-36.476 2.976-36.476s.885-3.808-.272-6.209c3.884-.309 5.756 4.939 5.236 8.275-.396 2.572-.777 5.151-1.204 7.722a24.487 24.487 0 00-.389 7.323c.482 3.255 2.253 6.127 3.411 9.186 1.157 3.06 1.616 6.803-.319 9.406z"
          opacity="0.51"
        ></path>
        <path
          fill="url(#paint21_linear_64_1040)"
          d="M170.676 261.867l-86.914 25.859c-15.076 4.485-29.093 12.211-41.16 22.684-12.068 10.473-21.92 23.464-28.929 38.146H3.574A111.188 111.188 0 010 322.25v-2.246c.133-21.074 6.34-41.92 19.274-58.104l151.402-.033z"
          opacity="0.61"
        ></path>
        <path
          fill="url(#paint22_linear_64_1040)"
          d="M153.997 348.556h7.971v71.847h-7.971v-71.847z"
          opacity="0.61"
        ></path>
        <path
          fill="#3F3D56"
          d="M638.92 299.313c-.635 12.996-3.808 25.718-9.322 37.38a109.392 109.392 0 01-6.914 12.278c-8.608 13.246-19.865 24.41-32.628 33.556a158 158 0 01-29.459 16.452L540.003 395l-39.473-7.624c-1.934-.374-3.806-.61-5.578-.814-7.279-.862-12.942-1.285-15.078-9.023-2.649-9.528 0-24.109 0-34.101 0-17.364 1.732-38.039 16.166-46.566 11.979-7.063 27.19-2.075 40.56-5.346a7.435 7.435 0 003.527-1.628c1.554-1.505 1.794-3.946 1.833-6.159.334-16.802-5.026-35.647 4.56-49.121 7.334-10.301 20.75-13.141 32.963-14.329 3.759-.377 7.534-.549 11.311-.513 1.833 0 3.664.082 5.492.245 12.655 1.025 24.712 5.28 31.144 16.192 3.442 5.825 4.661 12.742 5.811 19.487.521 3.116 1.041 6.257 1.554 9.398 1.709 11.277 3.263 22.758 4.125 34.215z"
        ></path>
        <path
          fill="#25233A"
          d="M638.92 299.313c-.635 12.996-3.808 25.718-9.322 37.38a109.392 109.392 0 01-6.914 12.278c-4.731 2.107-10.426 2.083-15.7 2.164-8.303.131-16.587.876-24.79 2.23-3.682.602-7.52 1.407-10.41 3.881-3.814 3.255-5.135 8.812-5.345 13.979-.209 5.167.451 10.398-.388 15.5a10.199 10.199 0 01-1.81 4.744c-1.981 2.498-5.438 2.945-8.546 3.149a206.72 206.72 0 01-15.762.407 206.736 206.736 0 01-39.364-4.329c-2.245-.472-4.863-1.31-5.577-3.597a2.879 2.879 0 01-.109-.52 8.163 8.163 0 01.28-3.198l7.108-31.871c.963-4.329 1.957-8.739 4.156-12.539 4.381-7.608 12.709-11.497 20.517-14.939l21.022-9.284c5.748-2.522 9.656-2.848 9.143-9.935-1.126-15.671-4.265-30.919-4.265-46.737 0-4.54.202-9.235 2.067-13.32 1.864-4.085 5.212-7.193 8.654-9.927 8.26-6.447 17.418-11.527 27.151-15.061 1.834 0 3.664.082 5.493.244 12.655 1.026 24.711 5.281 31.144 16.192 3.441 5.826 4.661 12.742 5.811 19.488.52 3.116 1.041 6.257 1.553 9.398 1.127 7.136 2.16 14.345 2.991 21.57.482 4.199.87 8.43 1.212 12.653z"
        ></path>
        <path
          fill="#3F3D56"
          d="M638.92 299.312c-.635 12.997-3.808 25.719-9.322 37.38a109.317 109.317 0 01-6.914 12.279c-4.731 2.107-10.425 2.083-15.7 2.164-8.303.131-16.587.876-24.79 2.229-3.682.603-7.52 1.408-10.41 3.882 0 0 4.824-27.925 24.502-37.332 19.678-9.406 41.422-33.27 41.422-33.27.482 4.214.871 8.445 1.212 12.668z"
        ></path>
        <path
          fill="url(#paint23_linear_64_1040)"
          d="M207.056 241.606a5.297 5.297 0 01-.31-.308l.069.065c.091.069.172.151.241.243z"
        ></path>
        <path
          fill="#fff"
          d="M198.449 228.661s1.126 13.092 4.016 16.42c-3.684-3.819-6.57-8.4-8.475-13.45 0 0 12.429-89.406 25.372-104.963l3.48 1.937-24.393 100.056zm168.594-70.577l.179 8.95-11.156 29.414-34.089 18.715-1.911-2.563 33.879-19.268s10.573-22.815 13.098-35.248zm-65.761 70.504l-.575 3.206-38.781-3.027c-10.736-.968-21.27-8.657-22.607-19.894l-13.385-49.634a13.233 13.233 0 01-.302-6.29 12.9 12.9 0 012.633-5.663s12.499 61.432 17.091 66.933c4.591 5.5 13.338 8.796 13.338 8.796l42.588 5.573z"
          opacity="0.24"
        ></path>
        <path
          fill="#fff"
          d="M214.895 324.886l-20.859 68.999-3.107-1.399 17.798-73.393s-6.93-19.976-12.026-34.541c-4.894-13.97 1.763-41.651 2.33-43.881l2.665 2.889s-6.992 32.758 2.004 48.332c8.996 15.573 11.195 32.994 11.195 32.994zm163.63-79.504a30.087 30.087 0 01-4.576-1.627 7.916 7.916 0 01-2.57-1.846 8.348 8.348 0 01-1.691-2.74 8.65 8.65 0 01.12-6.399c2.199-5.207 2.261-12.742 2.059-19.69-.458-15.981-4.319-33.255-4.607-40.48-.186-4.598.521-11.115 1.81-17.624l2.44-10.814s-2.238 17.543-2.813 22.351c-.574 4.809 4.7 32.189 6.611 36.16 1.912 3.971-1.468 27.339-1.009 32.888.458 5.55 4.226 9.821 4.226 9.821zm43.263 145.306l-3.177 1.627c-.366-1.912-7.163-37.103-16.742-62.604-7.155-19.015-10.604-57.77-11.948-77.559l3.939.814s5.842 50.529 12.259 69.211c6.417 18.681 15.669 68.511 15.669 68.511zm-1.927-180.529L409.56 244.91l-2.33-.463-.459-.163 9.672-32.547s-12.663-36.566-14.807-47.827a32.926 32.926 0 01-.101-11.294 9.943 9.943 0 012.48-5.161 9.22 9.22 0 014.831-2.789s-6.992 5.102-4.864 16.38c2.782 14.491 15.879 49.113 15.879 49.113zM396.315 135.7l-16.105 53.507-3.091 3.279 16.329-57.958.062-5.801 2.075 2.221v.008l.73 4.744z"
          opacity="0.24"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_64_1040"
          x1="254.476"
          x2="159.18"
          y1="209.907"
          y2="162.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#00272E"></stop>
          <stop offset="1" stopColor="#69B9EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_64_1040"
          x1="380.257"
          x2="420.566"
          y1="292.461"
          y2="156.231"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#00272E"></stop>
          <stop offset="1" stopColor="#69B9EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_64_1040"
          x1="376.533"
          x2="393.142"
          y1="134.854"
          y2="134.815"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7"></stop>
          <stop offset="0.42" stopColor="#EFD4D1"></stop>
          <stop offset="1" stopColor="#F2EAC9"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_64_1040"
          x1="387.41"
          x2="356.201"
          y1="122.214"
          y2="172.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7"></stop>
          <stop offset="0.42" stopColor="#EFD4D1"></stop>
          <stop offset="1" stopColor="#F2EAC9"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_64_1040"
          x1="361.522"
          x2="396.714"
          y1="110.743"
          y2="110.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7"></stop>
          <stop offset="0.42" stopColor="#EFD4D1"></stop>
          <stop offset="1" stopColor="#F2EAC9"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_64_1040"
          x1="299.05"
          x2="317.53"
          y1="228.69"
          y2="221.764"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7"></stop>
          <stop offset="0.42" stopColor="#EFD4D1"></stop>
          <stop offset="1" stopColor="#F2EAC9"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_64_1040"
          x1="306.37"
          x2="187.996"
          y1="203.43"
          y2="223.084"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#00272E"></stop>
          <stop offset="1" stopColor="#69B9EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_64_1040"
          x1="243.497"
          x2="253.203"
          y1="108.636"
          y2="111.491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7"></stop>
          <stop offset="0.42" stopColor="#EFD4D1"></stop>
          <stop offset="1" stopColor="#F2EAC9"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_64_1040"
          x1="321.165"
          x2="301.524"
          y1="214.532"
          y2="259.062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7"></stop>
          <stop offset="0.42" stopColor="#EFD4D1"></stop>
          <stop offset="1" stopColor="#F2EAC9"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_64_1040"
          x1="312.796"
          x2="308.249"
          y1="226.848"
          y2="242.773"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7"></stop>
          <stop offset="0.42" stopColor="#EFD4D1"></stop>
          <stop offset="1" stopColor="#F2EAC9"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_64_1040"
          x1="397.645"
          x2="419.615"
          y1="253.645"
          y2="253.594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7"></stop>
          <stop offset="0.42" stopColor="#EFD4D1"></stop>
          <stop offset="1" stopColor="#F2EAC9"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_64_1040"
          x1="224.085"
          x2="191.965"
          y1="391.607"
          y2="405.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68E1FD"></stop>
          <stop offset="0.01" stopColor="#67DDF9"></stop>
          <stop offset="0.12" stopColor="#5CB3CE"></stop>
          <stop offset="0.24" stopColor="#538EA9"></stop>
          <stop offset="0.37" stopColor="#4C718B"></stop>
          <stop offset="0.5" stopColor="#465A73"></stop>
          <stop offset="0.63" stopColor="#424A63"></stop>
          <stop offset="0.79" stopColor="#404059"></stop>
          <stop offset="0.99" stopColor="#3F3D56"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_64_1040"
          x1="274.301"
          x2="245.858"
          y1="390.956"
          y2="409.152"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68E1FD"></stop>
          <stop offset="0.01" stopColor="#67DDF9"></stop>
          <stop offset="0.12" stopColor="#5CB3CE"></stop>
          <stop offset="0.24" stopColor="#538EA9"></stop>
          <stop offset="0.37" stopColor="#4C718B"></stop>
          <stop offset="0.5" stopColor="#465A73"></stop>
          <stop offset="0.63" stopColor="#424A63"></stop>
          <stop offset="0.79" stopColor="#404059"></stop>
          <stop offset="0.99" stopColor="#3F3D56"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_64_1040"
          x1="402.607"
          x2="425.589"
          y1="386.766"
          y2="403.365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68E1FD"></stop>
          <stop offset="0.01" stopColor="#67DDF9"></stop>
          <stop offset="0.12" stopColor="#5CB3CE"></stop>
          <stop offset="0.24" stopColor="#538EA9"></stop>
          <stop offset="0.37" stopColor="#4C718B"></stop>
          <stop offset="0.5" stopColor="#465A73"></stop>
          <stop offset="0.63" stopColor="#424A63"></stop>
          <stop offset="0.79" stopColor="#404059"></stop>
          <stop offset="0.99" stopColor="#3F3D56"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_64_1040"
          x1="334.461"
          x2="378.607"
          y1="385.635"
          y2="406.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68E1FD"></stop>
          <stop offset="0.01" stopColor="#67DDF9"></stop>
          <stop offset="0.12" stopColor="#5CB3CE"></stop>
          <stop offset="0.24" stopColor="#538EA9"></stop>
          <stop offset="0.37" stopColor="#4C718B"></stop>
          <stop offset="0.5" stopColor="#465A73"></stop>
          <stop offset="0.63" stopColor="#424A63"></stop>
          <stop offset="0.79" stopColor="#404059"></stop>
          <stop offset="0.99" stopColor="#3F3D56"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_64_1040"
          x1="358.125"
          x2="418.382"
          y1="176.863"
          y2="141.32"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#00272E"></stop>
          <stop offset="1" stopColor="#69B9EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear_64_1040"
          x1="397.162"
          x2="353.254"
          y1="160.737"
          y2="163.942"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#00272E"></stop>
          <stop offset="1" stopColor="#69B9EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear_64_1040"
          x1="252.65"
          x2="214.437"
          y1="96.652"
          y2="164.731"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#00272E"></stop>
          <stop offset="1" stopColor="#69B9EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint18_linear_64_1040"
          x1="83.334"
          x2="103.043"
          y1="188.645"
          y2="188.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#00272E"></stop>
          <stop offset="1" stopColor="#69B9EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint19_linear_64_1040"
          x1="101.372"
          x2="124.414"
          y1="191.249"
          y2="191.249"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#00272E"></stop>
          <stop offset="1" stopColor="#69B9EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint20_linear_64_1040"
          x1="114.129"
          x2="83.124"
          y1="192.64"
          y2="268.232"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#1A7FC1"></stop>
        </linearGradient>
        <linearGradient
          id="paint21_linear_64_1040"
          x1="-78.385"
          x2="317.854"
          y1="235.879"
          y2="387.202"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#00272E"></stop>
          <stop offset="1" stopColor="#69B9EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint22_linear_64_1040"
          x1="-94.707"
          x2="301.531"
          y1="278.613"
          y2="429.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#00272E"></stop>
          <stop offset="1" stopColor="#69B9EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint23_linear_64_1040"
          x1="206.746"
          x2="207.056"
          y1="241.452"
          y2="241.452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68E1FD"></stop>
          <stop offset="1" stopColor="#69B9EB"></stop>
        </linearGradient>
        <clipPath id="clip0_64_1040">
          <path fill="#fff" d="M0 0H640V420H0z" transform="translate(0 .5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Deal;
