import React from "react";

function ArcBottomLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="132"
      height="226"
      fill="none"
      viewBox="0 0 132 226"
      {...props}
    >
      <g filter="url(#filter0_i_1097_1336)">
        <path
          stroke="#F2F8FA"
          strokeLinecap="round"
          strokeWidth="5"
          d="M129.177 2.956C122.632 48.68 88.274 156.701 3.195 223"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_i_1097_1336"
          width="131.982"
          height="227.044"
          x="0.695"
          y="0.456"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_1097_1336"></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default ArcBottomLeft;
