import * as React from "react";
const Web = (props) => (
  <svg
    width={92}
    height={92}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_1097_1424)">
      <path
        d="M50.4071 30.6686L40.6958 63.7887M83.5994 52.8614C77.6601 73.1171 56.0116 86.4657 35.246 82.6762C14.4804 78.8868 2.46128 59.3944 8.40055 39.1386C14.3398 18.8829 35.9884 5.53434 56.754 9.32377C77.5195 13.1132 89.5386 32.6057 83.5994 52.8614ZM30.5307 32.4007L37.7413 64.5981C38.3587 67.3549 42.0729 67.9838 44.1053 65.6756L67.5327 39.0693C69.3764 36.9754 68.4473 33.9607 65.8099 33.4794L35.1718 27.8884C32.4944 27.3998 29.9621 29.8618 30.5307 32.4007Z"
        stroke="#F2F8FA"
        strokeOpacity={0.01}
        strokeWidth={4}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1097_1424"
        x={0}
        y={0}
        width={93}
        height={94}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={2} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1097_1424" />
      </filter>
    </defs>
  </svg>
);
export default Web;
