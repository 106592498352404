/** @jsxImportSource @emotion/react */
import { colors } from "../theme";
import { css } from "@emotion/react";
import "../App.css";
import NumberOne from "../icons/numberOne";
import Friendly from "../icons/friendly";
import ArcTopLeft from "../icons/ArcTopLeft";
import ArcTopRight from "../icons/ArcTopRight";
import ArcBottomLeft from "../icons/ArcBottomLeft";
import ArcBottomRight from "../icons/ArcBottomRight";
import NumberTwo from "../icons/NumberTwo";
import NumberThree from "../icons/NumberThree";
import NumberFour from "../icons/NumberFour";
import NumberFive from "../icons/NumberFive";
import NumberSix from "../icons/NumberSix";
import Shopping from "../icons/shopping";
import Share from "../icons/share";
import Web from "../icons/web";
import Easy from "../icons/easy";
import Eco from "../icons/eco";
import TextBox from "./TextBox";

const MiniAppMain = () => {
  const LeftItems = [
    {
      icon: NumberOne,
      mood: Friendly,
      title: "Easy to use User Interface",
      text: "Whether you're focused on providing a native Telegram experience for your users or creating a unique user interface, our team is here to support you every step of the way.",
    },
    {
      icon: NumberTwo,
      mood: Shopping,
      title: "Easy Shopping",
      text: "Payments made easy on mini apps! We provide the best solutions for accepting payments from shoppers worldwide.",
    },
    {
      icon: NumberThree,
      mood: Share,
      title: "Instant Shareable features",
      text: "Any product, or service of a mini app can be instantly shared with millions of people on Telegram.",
    },
  ];

  const RightItems = [
    {
      icon: NumberFour,
      mood: Web,
      title: "Web3 for real people",
      text: "Telegram is the ideal platform for creating dApps! We'll help you transform your Web2 solutions into Web3 or develop a fully native dApp on Telegram from scratch.",
    },
    {
      icon: NumberFive,
      mood: Easy,
      title: "Native experience",
      text: "Mini Apps can be developed to feel as native as mobile applications, but within a powerful social network like Telegram, growth becomes much easier!",
    },
    {
      icon: NumberSix,
      mood: Eco,
      title: "More economical",
      text: "Want to test your idea with an MVP? We're here to help you launch it as a Mini App.",
    },
  ];

  return (
    <div>
      <div
        css={css({
          "@media (min-width:1080px)": {
            display: "none",
          },
        })}
      >
        <h2
          css={css({
            color: colors.light,
            fontSize: "36px",
            fontWeight: "bolder",
            fontFamily: "Noto Sans",
            textAlign: "center",
            marginBottom: "8rem",
            "@media (min-width: 540px) and (max-width: 640px)": {
              marginTop: "6rem",
            },
            "@media (min-width: 640px) and (max-width: 768px)": {
              marginTop: "8rem",
            },
          })}
        >
          Why Mini apps?
        </h2>
      </div>
      <div
        css={css({
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "1rem",
          backgroundColor: colors.background,
          "@media (max-width:1080px)": {
            flexDirection: "column",
            gap: "2rem",
          },
        })}
      >
        <div
          css={css({
            display: "flex",
            flexDirection: "column",
            "@media (max-width:1080px)": {
              alignItems: "center",
              gap: "3rem",
            },
          })}
        >
          {LeftItems.map((item, index) => (
            <TextBox key={index} {...item} />
          ))}
        </div>
        <div
          css={css({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            "@media (max-width:1080px)": {
              display: "none",
            },
          })}
        >
          <div
            css={css({
              "@media (max-width:1080px)": {
                display: "none",
              },
            })}
          >
            <ArcTopLeft
              css={css({
                "@media (min-width: 768px) and (max-width: 1373px)": {
                  position: "absolute",
                  margin: "0",
                  top: "16%",
                  right: "63%",
                },
                "@media (min-width: 768px) and (max-width: 1073px)": {
                  top: "27%",
                },
              })}
            />
            <ArcTopRight
              css={css({
                marginLeft: "2rem",
                position: "absolute",
                "@media (min-width: 768px) and (max-width: 1373px)": {
                  position: "absolute",
                  margin: "0",
                  top: "16%",
                  left: "63%",
                },
                "@media (min-width: 768px) and (max-width: 1073px)": {
                  top: "27%",
                },
              })}
            />
          </div>
          <div
            css={css({
              "@media (max-width:1080px)": {
                display: "none",
              },
            })}
          >
            <h2
              css={css({
                color: colors.light,
                fontSize: "36px",
                fontWeight: "bolder",
                fontFamily: "Noto Sans",
                margin: "2rem 0rem",
                textAlign: "center",
                whiteSpace: "nowrap",
              })}
            >
              Why Mini apps?
            </h2>
          </div>
          <div
            css={css({
              "@media (max-width:1080px)": {
                display: "none",
              },
            })}
          >
            <ArcBottomLeft
              css={css({
                "@media (min-width: 768px) and (max-width: 1373px)": {
                  position: "absolute",
                  margin: "0",
                  right: "63%",
                },
              })}
            />
            <ArcBottomRight
              css={css({
                position: "absolute",
                marginLeft: "2rem",
                "@media (min-width: 768px) and (max-width: 1373px)": {
                  position: "absolute",
                  margin: "0",
                  left: "63%",
                },
              })}
            />
          </div>
        </div>
        <div
          css={css({
            display: "flex",
            flexDirection: "column",
            "@media (max-width:1080px)": {
              gap: "3rem",
              alignItems: "center",
            },
          })}
        >
          {RightItems.map((item, index) => (
            <TextBox isRight={true} key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MiniAppMain;
