import * as React from "react";

const CurveOne = (props) => (
  <svg
    width={321}
    height={321}
    viewBox="0 0 321 321"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M89.3614 44.1754C81.6786 87.2107 67.5937 76.6046 34.302 105.682C-26.6185 158.892 -0.169314 302.387 81.6786 281.451C132.86 268.359 178.993 346.586 241.735 310.529C269.793 294.405 244.296 220.969 288.036 201.484C334.031 180.993 329.731 131.304 288.036 105.682C246.34 80.0613 267.109 72.4013 232.772 26.7287C198.434 -18.9439 97.4314 -1.0288 89.3614 44.1754Z"
      fill="white"
      fillOpacity={0.2}
    />
  </svg>
);
export default CurveOne;
