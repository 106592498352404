/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { AboutSection } from "./About";

const AboutAds = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "1440px",
        }}
      >
        <AboutSection
          heading="Boost with us"
          content="Grow on Telegram and spend your advertising budget efficiently. Our team will help you boost your business on Telegram by using the latest advertising and marketing methods. We are here to support your growth on Telegram, whether it's a Telegram Mini-App (TMA), a Channel, or even a large community that you plan to build. We are here to help you succeed."
          imageSrc="./img/stock.png"
        />
        <AboutSection
          heading="Secure Progress"
          content="Our team is here to support you every step of the way. We'll assist with user targeting, copywriting, and campaign management to help you achieve success in your Telegram Ads campaign."
          imageSrc="./img/cycle.png"
          containerStyle={css({
            "@media (min-width: 768px)": {
              flexDirection: "row-reverse",
            },
          })}
        />
      </div>
    </div>
  );
};

export default AboutAds;
