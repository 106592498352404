function Home(props) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1106 25.4199C11.1106 24.6065 11.1118 24.0603 11.1591 23.6471C11.2047 23.2496 11.2846 23.0708 11.3776 22.9524C11.4392 22.8739 11.5101 22.8031 11.5885 22.7415C11.7069 22.6485 11.8857 22.5686 12.2833 22.523C12.6964 22.4757 13.2426 22.4745 14.056 22.4745H29.6395C30.1321 22.4745 30.4261 22.4761 30.6369 22.5044C30.7334 22.5174 30.7818 22.5327 30.8036 22.5417C30.806 22.5427 30.8081 22.5436 30.8097 22.5443C30.8115 22.5452 30.8129 22.5458 30.8139 22.5464L30.8163 22.5477L30.8177 22.5502C30.818 22.5509 30.8185 22.5518 30.819 22.5529C30.8199 22.5548 30.8211 22.5573 30.8224 22.5605C30.8313 22.5822 30.8466 22.6306 30.8596 22.7272C30.8879 22.9379 30.8895 23.2319 30.8895 23.7245V25.8666C30.8895 28.0559 30.8886 29.6396 30.7595 30.871C30.6318 32.0892 30.3855 32.8764 29.9508 33.502C29.5941 34.0152 29.1487 34.4606 28.6355 34.8173C28.0099 35.252 27.2227 35.4983 26.0045 35.626C24.7731 35.7551 23.1894 35.756 21.0001 35.756C18.8108 35.756 17.2271 35.7551 15.9957 35.626C14.7775 35.4983 13.9903 35.252 13.3647 34.8173C12.8515 34.4606 12.406 34.0152 12.0494 33.502C11.6147 32.8764 11.3683 32.0892 11.2407 30.871C11.1116 29.6396 11.1106 28.0559 11.1106 25.8666V25.4199ZM30.8164 22.5478C30.8164 22.5478 30.8165 22.5479 30.8165 22.5478L30.8167 22.548L30.8164 22.5478ZM20.2398 7.02056C20.6883 6.67715 21.3113 6.67715 21.7598 7.02056L34.4474 16.7363C35.3961 17.4628 34.8823 18.9787 33.6874 18.9787H8.31225C7.11732 18.9787 6.60355 17.4628 7.55228 16.7363L20.2398 7.02056Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Home;
