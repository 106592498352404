import React from "react";

function NumberOne(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="82"
      height="82"
      fill="none"
      viewBox="0 0 82 82"
      {...props}
    >
      <path
        fill="#006180"
        d="M0 24C0 10.745 10.745 0 24 0h34c13.255 0 24 10.745 24 24v34c0 13.255-10.745 24-24 24H24C10.745 82 0 71.255 0 58V24z"
      ></path>
      <path
        fill="#fff"
        d="M48.25 60h-8.6V40.75 38.8c0-.867.017-1.783.05-2.75.033-1 .067-1.867.1-2.6-.267.333-.633.733-1.1 1.2-.467.433-.9.817-1.3 1.15l-4.1 3.35-4.25-5.3L40.9 24.3h7.35V60z"
      ></path>
    </svg>
  );
}

export default NumberOne;
