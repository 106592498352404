import * as React from "react";

const WaveBottomMobile = (props) => (
  <svg
    width={375}
    height={177}
    viewBox="0 0 375 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M-548.842 116.505L-555.418 113.496C-605.504 90.5843 -645.682 50.4287 -668.621 0.356369H1043.62L1022 13.0238C914.683 75.8915 789.653 101.673 666.222 86.3848L532.536 69.8266C466.223 61.6132 399.408 80.5769 347.298 122.402C285.422 172.064 203.479 189.032 126.97 168.025L-114.951 101.6C-183.657 82.7346 -256.546 85.8522 -323.39 110.515L-359.069 123.679C-420.79 146.451 -489.016 143.872 -548.842 116.505Z"
      fill="#006180"
    />
  </svg>
);
export default WaveBottomMobile;
