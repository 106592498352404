import * as React from "react";
const Eco = (props) => (
  <svg
    width={92}
    height={92}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_1097_1427)">
      <path
        d="M31.4453 37.0895L6.71293 62.9969C6.06566 63.675 6.62315 64.7909 7.554 64.6805L17.2497 63.5308C17.8698 63.4573 18.4049 63.9638 18.3654 64.587L17.6538 75.8313C17.5945 76.7685 18.7429 77.2633 19.3832 76.5764L35.2684 59.5373C36.2246 58.5115 37.764 58.2851 38.9751 58.9921L50.5342 65.7397C51.9391 66.5598 53.7417 66.1108 54.5977 64.7274L73.6021 34.0153C73.9223 33.4978 74.6267 33.3839 75.0937 33.7742L81.4181 39.0597C82.0692 39.6039 83.0594 39.1409 83.0594 38.2924V17.2916C83.0594 15.3182 81.1868 13.8823 79.2809 14.3944L58.0837 20.0899C57.2212 20.3217 57.072 21.4817 57.8477 21.9243L66.1487 26.66C66.6957 26.9721 66.8216 27.706 66.4098 28.1825L50.0765 47.0824C48.9272 48.4123 46.8859 48.4737 45.6587 47.2152L35.7632 37.0667C34.577 35.8503 32.6184 35.8606 31.4453 37.0895Z"
        stroke="#ffffff"
        strokeWidth={4}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1097_1427"
        x={0}
        y={0}
        width={93}
        height={94}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={2} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1097_1427" />
      </filter>
    </defs>
  </svg>
);
export default Eco;
