import * as React from "react";

const CurveThird = (props) => (
  <svg
    width={321}
    height={321}
    viewBox="0 0 321 321"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M276.879 231.693C233.844 239.376 244.45 253.461 215.372 286.752C162.162 347.673 18.667 321.224 39.6031 239.376C52.6949 188.194 -25.5315 142.062 10.5251 79.3194C26.6496 51.2611 100.085 76.7585 119.571 33.0184C140.061 -12.9762 189.751 -8.67715 215.372 33.0184C240.993 74.714 248.653 53.9449 294.326 88.2824C339.998 122.62 322.083 223.623 276.879 231.693Z"
      fill="white"
      fillOpacity={0.2}
    />
  </svg>
);
export default CurveThird;
