import React from "react";

function Dev(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 320 321" {...props}>
      <g clipPath="url(#clip0_24_597)">
        <path
          fill="#fff"
          d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
        ></path>
        <g filter="url(#filter0_d_24_597)">
          <rect
            width="183.379"
            height="332.052"
            x="145.818"
            y="-61.002"
            fill="#628000"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
          ></rect>
        </g>
        <g filter="url(#filter1_d_24_597)">
          <rect
            width="211.834"
            height="373.502"
            x="-7.702"
            y="-36.036"
            fill="#006180"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
          ></rect>
        </g>
        <g filter="url(#filter2_d_24_597)">
          <rect
            width="462.91"
            height="275.174"
            x="-39.845"
            y="144.793"
            fill="#800062"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
          ></rect>
        </g>
        <g filter="url(#filter3_d_24_597)">
          <rect
            width="25.584"
            height="25.584"
            x="274.05"
            y="21.224"
            fill="#800062"
            rx="12.792"
          ></rect>
        </g>
        <g filter="url(#filter4_d_24_597)">
          <rect
            width="25.584"
            height="25.584"
            x="239.091"
            y="21.224"
            fill="#628000"
            rx="12.792"
          ></rect>
        </g>
        <g filter="url(#filter5_d_24_597)">
          <rect
            width="25.584"
            height="25.584"
            x="204.132"
            y="21.224"
            fill="#006180"
            rx="12.792"
          ></rect>
        </g>
        <g filter="url(#filter6_d_24_597)">
          <path
            stroke="#006180"
            strokeWidth="26.667"
            d="M211.81 98.612l56.783 65.483-56.783 65.482"
          ></path>
        </g>
        <g filter="url(#filter7_d_24_597)">
          <path
            stroke="#006180"
            strokeWidth="26.667"
            d="M108.19 98.612l-56.783 65.483 56.783 65.482"
          ></path>
        </g>
        <g filter="url(#filter8_d_24_597)">
          <path stroke="#006180" strokeWidth="26.667" d="M178.059 107.857l-36.118 118.477"></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_24_597"
          width="186.934"
          height="338.274"
          x="145.818"
          y="-61.002"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_597"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_597" result="shape"></feBlend>
        </filter>
        <filter
          id="filter1_d_24_597"
          width="215.39"
          height="379.724"
          x="-7.702"
          y="-36.036"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_597"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_597" result="shape"></feBlend>
        </filter>
        <filter
          id="filter2_d_24_597"
          width="466.466"
          height="281.396"
          x="-39.845"
          y="144.793"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_597"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_597" result="shape"></feBlend>
        </filter>
        <filter
          id="filter3_d_24_597"
          width="29.14"
          height="29.139"
          x="274.05"
          y="21.224"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_597"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_597" result="shape"></feBlend>
        </filter>
        <filter
          id="filter4_d_24_597"
          width="29.14"
          height="29.139"
          x="239.091"
          y="21.224"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_597"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_597" result="shape"></feBlend>
        </filter>
        <filter
          id="filter5_d_24_597"
          width="29.14"
          height="29.139"
          x="204.132"
          y="21.224"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_597"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_597" result="shape"></feBlend>
        </filter>
        <filter
          id="filter6_d_24_597"
          width="88.06"
          height="151.991"
          x="201.736"
          y="89.877"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_597"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_597" result="shape"></feBlend>
        </filter>
        <filter
          id="filter7_d_24_597"
          width="88.06"
          height="151.991"
          x="33.759"
          y="89.877"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_597"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_597" result="shape"></feBlend>
        </filter>
        <filter
          id="filter8_d_24_597"
          width="65.084"
          height="130.628"
          x="129.235"
          y="103.559"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_597"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_597" result="shape"></feBlend>
        </filter>
        <clipPath id="clip0_24_597">
          <path
            fill="#fff"
            d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Dev;
