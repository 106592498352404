import * as React from "react";

const Share = (props) => (
  <svg
    width={92}
    height={92}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_1097_1418)">
      <path
        d="M27.5693 49.1675L45.5325 30.7122M67.6789 59.7486L54.514 30.7122M30.4797 56.0936C30.4797 61.0088 26.4952 64.9934 21.58 64.9934C16.6647 64.9934 12.6802 61.0088 12.6802 56.0936C12.6802 51.1784 16.6647 47.1938 21.58 47.1938C26.4952 47.1938 30.4797 51.1784 30.4797 56.0936ZM60.0007 22.4481C60.0007 27.3633 56.0162 31.3479 51.101 31.3479C46.1857 31.3479 42.2012 27.3633 42.2012 22.4481C42.2012 17.5329 46.1857 13.5483 51.101 13.5483C56.0162 13.5483 60.0007 17.5329 60.0007 22.4481ZM79.3198 69.5518C79.3198 74.467 75.3353 78.4516 70.42 78.4516C65.5048 78.4516 61.5203 74.467 61.5203 69.5518C61.5203 64.6366 65.5048 60.652 70.42 60.652C75.3353 60.652 79.3198 64.6366 79.3198 69.5518Z"
        stroke="#F2F8FA"
        strokeOpacity={0.01}
        strokeWidth={4}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1097_1418"
        x={0}
        y={0}
        width={93}
        height={94}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={2} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1097_1418" />
      </filter>
    </defs>
  </svg>
);
export default Share;
