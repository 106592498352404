/** @jsxImportSource @emotion/react */
import { colors } from "../theme";
import { css } from "@emotion/react";
import BigLogo from "../icons/BigLogo";
import "../App.css";
const OurGoal = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        css={css({
          maxWidth: "1440px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: colors.background,
          "@media (max-width:768px)": {
            flexDirection: "column",
          },
        })}
      >
        <div
          css={css({
            width: "100%",
            paddingLeft: "40px",
            "@media (max-width:768px)": {
              marginTop: "34px",
              padding: "30px",
            },
          })}
        >
          <h2
            css={css({
              fontSize: "32px",
              lineHeight: "1.6",
              fontWeight: "normal",
              "@media (max-width:768px)": {
                fontSize: "24px",
                marginBottom: "16px",
              },
            })}
          >
            What is our <span style={{ color: colors.primary }}>Mission</span>?
          </h2>
          <p
            css={css({
              fontSize: "20px",
              paddingTop: "28px",
              "@media (max-width:768px)": {
                fontSize: "16px",
                paddingTop: "0px",
              },
            })}
          >
            Our mission is to revolutionize the world of product development by delivering
            exceptional solutions across diverse categories. We strive to empower raw ideas, ignite
            innovation, and exceed expectations, enabling our clients to bring their visions to life
            with a working solution!
          </p>
        </div>
        <BigLogo style={{ width: "100%" }} />
        <div
          css={css({
            width: "100%",
            paddingRight: "40px",

            "@media (max-width:768px)": {
              padding: "30px",
            },
          })}
        >
          <h2
            css={css({
              fontSize: "32px",
              lineHeight: "1.6",
              fontWeight: "normal",
              "@media (max-width:768px)": {
                fontSize: "26px",
                marginBottom: "16px",
              },
            })}
          >
            What is our <span style={{ color: colors.primary }}>Vision</span>?
          </h2>
          <p
            css={{
              fontSize: "20px",
              paddingTop: "28px",
              "@media (max-width:768px)": {
                fontSize: "16px",
                paddingTop: "0px",
              },
            }}
          >
            We are driven by a relentless passion for pushing boundaries and creating amazing
            products. Our team dares to dream big, overcoming challenges and turning them into
            opportunities. Tell us about your vision; we will be on your side throughout the
            journey.
          </p>
        </div>
      </div>
    </div>
  );
};
export default OurGoal;
