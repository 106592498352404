import React from "react";

function NumberSix(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="83"
      height="82"
      fill="none"
      viewBox="0 0 83 82"
      {...props}
    >
      <path
        fill="#006180"
        d="M.667 24c0-13.255 10.745-24 24-24h34c13.255 0 24 10.745 24 24v34c0 13.255-10.745 24-24 24h-34c-13.255 0-24-10.745-24-24V24z"
      ></path>
      <path
        fill="#fff"
        d="M28.917 44.85c0-2.033.15-4.05.45-6.05.3-2.033.816-3.933 1.55-5.7a14.922 14.922 0 013.2-4.75c1.4-1.4 3.15-2.5 5.25-3.3 2.133-.8 4.733-1.2 7.8-1.2.7 0 1.533.033 2.5.1.966.067 1.783.167 2.45.3v6.7c-.667-.133-1.4-.233-2.2-.3a20.26 20.26 0 00-2.6-.15c-2.034 0-3.734.25-5.1.75-1.334.467-2.4 1.15-3.2 2.05-.767.9-1.334 1.967-1.7 3.2-.367 1.233-.584 2.617-.65 4.15h.3c.433-.8.966-1.5 1.6-2.1.666-.633 1.466-1.133 2.4-1.5.966-.367 2.1-.55 3.4-.55 2.1 0 3.9.45 5.4 1.35 1.5.9 2.65 2.2 3.45 3.9.833 1.7 1.25 3.767 1.25 6.2 0 2.633-.5 4.9-1.5 6.8-1 1.867-2.417 3.3-4.25 4.3-1.834.967-4.034 1.45-6.6 1.45-1.867 0-3.6-.317-5.2-.95s-3-1.583-4.2-2.85c-1.2-1.3-2.134-2.933-2.8-4.9-.667-1.967-1-4.283-1-6.95zm13.05 8.85c1.266 0 2.283-.45 3.05-1.35.8-.9 1.2-2.317 1.2-4.25 0-1.567-.334-2.783-1-3.65-.667-.9-1.717-1.35-3.15-1.35-.967 0-1.8.217-2.5.65-.7.433-1.25 1-1.65 1.7a4.297 4.297 0 00-.55 2.1c0 .733.1 1.467.3 2.2.2.733.483 1.4.85 2 .366.6.833 1.083 1.4 1.45.6.333 1.283.5 2.05.5z"
      ></path>
    </svg>
  );
}

export default NumberSix;
