/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { colors } from "../theme";
import { handleCalendry } from "../utils/Calendry";

export const InfoField = (props) => {
  return (
    <div style={{ position: "relative", margin: "6px", width: "100%" }}>
      <a
        href={props.isMail ? `mailto:${props.info}` : props.isPhone ? `tel:${props.info}` : null}
        css={css({
          color: "black !important",
        })}
      >
        <div
          css={css({
            width: "100%",
            whiteSpace: props.isAdress ? "unset" : "nowrap",
            padding: "10px 50px 14px 54px",
            maxHeight: "146px",
            borderRadius: "8px",
            fontSize: "20px",
            fontWeight: "normal",
            border: `1px solid ${colors.lighter}`,
          })}
        >
          <div
            css={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: props.readOnly ? "21%" : "50%",
              transform: "translateY(-50%)",
              left: props.readOnly ? "2%" : "6px",
              width: "38px",
              height: "auto",
              "@media (max-width:768px)": {
                top: props.readOnly ? "20%" : "58%",
              },
            })}
          >
            {props.icon}
          </div>

          {props.isAdress ? <p>{props.country}</p> : null}
          <p
            css={css({
              textAlign: props.isAdress ? "left" : "unset",
              letterSpacing: props.isAdress && "0",
            })}
          >
            {props.info}
          </p>

          {props.hasBtn ? (
            <button
              type="button"
              className="btn"
              onClick={handleCalendry()}
              css={css({
                backgroundColor: colors.primary,
                color: colors.whiteText,
                width: "75px",
                cursor: "pointer",
                height: "auto",
                padding: "9px 0px",
                borderRadius: "8px",
                position: "absolute",
                right: "8px",
                top: "11px",
                boxShadow: "none",
                border: "none",
                transition: "background-color 0.2s ease-out",
                ":hover": {
                  backgroundColor: colors.dark,
                },
                "@media (max-width:768px)": {
                  top: "11px",
                },
              })}
            >
              Book
            </button>
          ) : null}
        </div>
      </a>
    </div>
  );
};
