import * as React from "react";

const Shopping = (props) => (
  <svg
    width={92}
    height={92}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_1097_1419)">
      <path
        d="M40.4416 34.1275C42.7399 25.021 47.3205 6.46582 60.8601 6.46582C74.4807 6.46582 78.5947 28.0288 77.2992 38.4709M15.1842 48.8312L38.7208 51.7374M34.8867 60.0899L44.6904 61.1868M7.99609 57.1921L22.8634 58.9128M11.6804 67.8256L56.3082 72.6204M52.8044 45.3273L27.3555 42.3768M33.15 81.1429L74.1826 85.4837C79.7044 86.0678 84.4035 81.5008 83.9769 75.9646L81.6246 45.4312C81.2953 41.1576 77.9623 37.7281 73.6998 37.2772L42.9962 34.0292C38.6567 33.5701 34.626 36.33 33.4849 40.542L25.5082 69.9827C24.0779 75.2618 27.7109 80.5675 33.15 81.1429Z"
        stroke="#F2F8FA"
        strokeOpacity={0.01}
        strokeWidth={4}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1097_1419"
        x={0}
        y={0}
        width={93}
        height={94}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={2} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1097_1419" />
      </filter>
    </defs>
  </svg>
);
export default Shopping;
