import React from "react";

function Support(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 320 321" {...props}>
      <g clipPath="url(#clip0_24_601)">
        <path
          fill="#fff"
          d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
        ></path>
        <g filter="url(#filter0_d_24_601)">
          <rect
            width="204.818"
            height="370.871"
            x="327.642"
            y="-62.545"
            fill="#628000"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
            transform="rotate(45 327.642 -62.545)"
          ></rect>
        </g>
        <g filter="url(#filter1_d_24_601)">
          <rect
            width="236.599"
            height="417.167"
            x="186.678"
            y="-164.073"
            fill="#006180"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
            transform="rotate(45 186.678 -164.073)"
          ></rect>
        </g>
        <g filter="url(#filter2_d_24_601)">
          <rect
            width="517.028"
            height="307.344"
            x="18.479"
            y="-46.645"
            fill="#800062"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
            transform="rotate(45 18.479 -46.645)"
          ></rect>
        </g>
        <path
          fill="#800062"
          fillRule="evenodd"
          d="M165.719 211.405c.673-3.91 5.403-5.07 8.208-2.264l26.824 26.823a5.333 5.333 0 004.652 1.489l24.757-4.147a5.332 5.332 0 004.379-4.379l4.146-24.757a5.333 5.333 0 00-1.489-4.652l-26.823-26.823c-2.805-2.805-1.645-7.535 2.265-8.208 17.806-3.065 36.777 2.277 50.525 16.025 15.932 15.932 20.575 38.879 13.927 58.899l59.354 59.355-36.446 36.446-59.354-59.354c-20.021 6.648-42.968 2.005-58.9-13.927-13.748-13.748-19.09-32.719-16.025-50.526z"
          clipRule="evenodd"
        ></path>
        <g fill="#006180" filter="url(#filter3_d_24_601)">
          <path d="M126.456 87.01c0-3.027.822-5.133 2.468-6.318 1.645-1.25 3.652-1.875 6.022-1.875 2.303 0 4.277.625 5.923 1.876 1.645 1.184 2.468 3.29 2.468 6.318 0 2.895-.823 5.001-2.468 6.318-1.646 1.25-3.62 1.875-5.923 1.875-2.37 0-4.377-.625-6.022-1.875-1.646-1.317-2.468-3.423-2.468-6.318zM91.063 38.932c7.24 0 12.768 1.58 16.585 4.738 3.883 3.093 5.824 7.865 5.824 14.315v35.934h-10.267l-2.862-7.305h-.395c-1.514 1.908-3.094 3.488-4.739 4.738-1.58 1.25-3.422 2.14-5.528 2.666-2.04.592-4.574.888-7.602.888-3.159 0-6.022-.592-8.588-1.777-2.501-1.25-4.476-3.126-5.924-5.627-1.447-2.567-2.171-5.791-2.171-9.674 0-5.726 2.007-9.938 6.022-12.637 4.014-2.764 10.036-4.278 18.065-4.54l9.379-.297v-2.37c0-2.83-.757-4.902-2.27-6.219-1.449-1.316-3.489-1.974-6.121-1.974-2.633 0-5.2.395-7.7 1.184a57.591 57.591 0 00-7.504 2.765l-4.837-9.971c2.896-1.514 6.088-2.698 9.576-3.554 3.554-.856 7.24-1.283 11.057-1.283zm2.073 30.208c-4.739.132-8.03.987-9.872 2.567-1.843 1.58-2.764 3.653-2.764 6.22 0 2.237.658 3.85 1.974 4.836 1.316.922 3.028 1.383 5.134 1.383 3.159 0 5.824-.922 7.996-2.765 2.172-1.908 3.258-4.574 3.258-7.996v-4.442l-5.726.197z"></path>
        </g>
        <g filter="url(#filter4_d_24_601)">
          <path
            fill="#006180"
            d="M160 62.922V52.457h47.879v10.465H160zm0 18.757V71.115h47.879V81.68H160z"
          ></path>
        </g>
        <g filter="url(#filter5_d_24_601)">
          <path
            fill="#006180"
            d="M135.407 191.517c0 6.58-.658 13.037-1.974 19.37-1.234 6.333-3.208 12.337-5.922 18.013a66.53 66.53 0 01-10.24 15.915h-14.928a82.56 82.56 0 009.253-16.409 90.328 90.328 0 005.798-18.013 93.156 93.156 0 001.974-18.999c0-6.498-.658-12.914-1.974-19.247a95.548 95.548 0 00-5.798-18.383 77.939 77.939 0 00-9.377-16.532h15.052a67.953 67.953 0 0110.24 16.162 78.725 78.725 0 015.922 18.383c1.316 6.415 1.974 12.995 1.974 19.74z"
          ></path>
        </g>
        <g filter="url(#filter6_d_24_601)">
          <path
            fill="#006180"
            d="M65.954 225.938c-6.662 0-12.338-1.192-17.026-3.578-4.688-2.467-8.266-6.251-10.734-11.35-2.467-5.1-3.7-11.638-3.7-19.617 0-8.225 1.397-14.928 4.194-20.11 2.796-5.182 6.662-9.006 11.597-11.474 5.017-2.467 10.775-3.701 17.273-3.701 4.606 0 8.595.452 11.967 1.357 3.454.905 6.457 1.974 9.006 3.208l-5.428 14.188c-2.879-1.151-5.593-2.097-8.143-2.838-2.467-.74-4.935-1.11-7.402-1.11-3.208 0-5.881.781-8.02 2.344-2.138 1.481-3.742 3.743-4.811 6.786-.987 2.961-1.48 6.703-1.48 11.227 0 4.441.534 8.143 1.603 11.104 1.151 2.878 2.797 5.058 4.935 6.539 2.139 1.398 4.73 2.097 7.773 2.097 3.865 0 7.279-.494 10.24-1.481 2.96-1.069 5.84-2.508 8.636-4.318v15.669c-2.796 1.809-5.757 3.084-8.883 3.824-3.043.823-6.909 1.234-11.597 1.234z"
          ></path>
        </g>
        <g filter="url(#filter7_d_24_601)">
          <path
            fill="#006180"
            d="M258.253 29.83l-26.259 70.487h-13.327l26.259-70.487h13.327z"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_24_601"
          width="401.397"
          height="404.064"
          x="70.012"
          y="-57.929"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_601"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_601" result="shape"></feBlend>
        </filter>
        <filter
          id="filter1_d_24_601"
          width="456.606"
          height="459.273"
          x="-103.688"
          y="-159.457"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_601"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_601" result="shape"></feBlend>
        </filter>
        <filter
          id="filter2_d_24_601"
          width="577.243"
          height="579.909"
          x="-194.23"
          y="-42.029"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_601"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_601" result="shape"></feBlend>
        </filter>
        <filter
          id="filter3_d_24_601"
          width="81.497"
          height="59.828"
          x="65.395"
          y="38.932"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_601"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_601" result="shape"></feBlend>
        </filter>
        <filter
          id="filter4_d_24_601"
          width="51.435"
          height="32.777"
          x="160"
          y="52.457"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_601"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_601" result="shape"></feBlend>
        </filter>
        <filter
          id="filter5_d_24_601"
          width="36.743"
          height="111.138"
          x="102.219"
          y="137.232"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_601"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_601" result="shape"></feBlend>
        </filter>
        <filter
          id="filter6_d_24_601"
          width="57.594"
          height="73.386"
          x="34.493"
          y="156.108"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_601"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_601" result="shape"></feBlend>
        </filter>
        <filter
          id="filter7_d_24_601"
          width="43.142"
          height="74.042"
          x="218.667"
          y="29.83"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_601"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_601" result="shape"></feBlend>
        </filter>
        <clipPath id="clip0_24_601">
          <path
            fill="#fff"
            d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Support;
