import * as React from "react";

const FirstCircle = (props) => (
  <svg
    width={822}
    height={713}
    viewBox="0 0 822 713"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_2397_1496)">
      <rect x={415.612} y={251.43} width={125.801} height={126.896} rx={62.9006} fill="#2488FF" />
    </g>
    <g filter="url(#filter1_d_2397_1496)">
      <rect
        x={386.264}
        y={221.826}
        width={184.498}
        height={186.103}
        rx={92.2489}
        fill="#2488FF"
        fillOpacity={0.5}
        shapeRendering="crispEdges"
      />
    </g>
    <rect
      x={357.914}
      y={194.278}
      width={241.198}
      height={241.198}
      rx={120.599}
      fill="#0F0662"
      fillOpacity={0.49}
    />
    <defs>
      <filter
        id="filter0_d_2397_1496"
        x={415.612}
        y={251.43}
        width={129.357}
        height={130.451}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2397_1496" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2397_1496"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2397_1496"
        x={386.264}
        y={221.826}
        width={188.054}
        height={189.658}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2397_1496" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2397_1496"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default FirstCircle;
