import React from "react";

function Friendly(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="92"
      fill="none"
      viewBox="0 0 92 92"
      {...props}
    >
      <g stroke="#F2F8FA" strokeOpacity="0.01" strokeWidth="4" filter="url(#filter0_i_1097_1414)">
        <path d="M85.277 45.96c0 21.714-17.603 39.317-39.318 39.317-21.714 0-39.318-17.603-39.318-39.318 0-21.715 17.604-39.318 39.318-39.318 21.715 0 39.318 17.603 39.318 39.318z"></path>
        <path d="M40.82 32.123c0 3.844-2.356 6.96-5.263 6.96-2.906 0-5.263-3.116-5.263-6.96s2.357-6.96 5.263-6.96c2.907 0 5.263 3.116 5.263 6.96zM68.938 32.123c0 3.844-2.356 6.96-5.263 6.96-2.906 0-5.262-3.116-5.262-6.96s2.356-6.96 5.262-6.96c2.907 0 5.263 3.116 5.263 6.96zM62.468 59.449c1.053.334 1.645 1.464 1.203 2.477A19.322 19.322 0 0127.038 50.29c.224-1.082 1.36-1.663 2.412-1.329L62.468 59.45z"></path>
      </g>
      <defs>
        <filter
          id="filter0_i_1097_1414"
          width="93"
          height="94"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_1097_1414"></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Friendly;
