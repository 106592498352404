import React from "react";

function NumberThree(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="82"
      height="82"
      fill="none"
      viewBox="0 0 82 82"
      {...props}
    >
      <path
        fill="#006180"
        d="M0 24C0 10.745 10.745 0 24 0h34c13.255 0 24 10.745 24 24v34c0 13.255-10.745 24-24 24H24C10.745 82 0 71.255 0 58V24z"
      ></path>
      <path
        fill="#fff"
        d="M52.35 32.2c0 1.667-.35 3.1-1.05 4.3-.7 1.2-1.633 2.2-2.8 3-1.167.767-2.483 1.333-3.95 1.7v.15c2.867.333 5.05 1.2 6.55 2.6 1.533 1.4 2.3 3.317 2.3 5.75 0 2-.517 3.833-1.55 5.5-1.033 1.633-2.633 2.933-4.8 3.9-2.133.933-4.9 1.4-8.3 1.4-2.1 0-3.983-.167-5.65-.5a25.787 25.787 0 01-4.8-1.45V51.4c1.567.8 3.167 1.4 4.8 1.8 1.667.367 3.2.55 4.6.55 2.533 0 4.283-.4 5.25-1.2 1-.8 1.5-1.933 1.5-3.4 0-.9-.233-1.667-.7-2.3-.467-.633-1.283-1.117-2.45-1.45-1.167-.333-2.833-.5-5-.5h-2.45v-6.45h2.5c2.033 0 3.6-.183 4.7-.55 1.133-.367 1.917-.867 2.35-1.5a4.04 4.04 0 00.65-2.25c0-1.133-.383-2-1.15-2.6-.767-.6-1.917-.9-3.45-.9-1.1 0-2.117.15-3.05.45-.933.3-1.767.633-2.5 1-.733.367-1.333.7-1.8 1l-3.75-5.75a22.137 22.137 0 013.15-1.8c1.167-.567 2.467-1 3.9-1.3 1.467-.3 3.15-.45 5.05-.45 3.667 0 6.567.75 8.7 2.25 2.133 1.467 3.2 3.517 3.2 6.15z"
      ></path>
    </svg>
  );
}

export default NumberThree;
