/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { colors } from "../theme";
import { boxStyle } from "../theme";
import { handleCalendry } from "../utils/Calendry";

const Book = () => {
  return (
    <div
      css={css({
        marginTop: "-13rem",
        position: "relative",
        "@media (min-width: 320px) and (max-width: 768px)": {
          marginTop: "-10rem",
        },
        "@media (min-width: 990px) and (max-width: 1280px)": {
          marginTop: "-15rem",
        },
        "@media (min-width: 768px) and (max-width: 990px)": {
          marginTop: "-14rem",
        },
        "@media (min-width: 1800px) and (max-width: 2100px)": {
          marginTop: "-15rem",
        },
        "@media (min-width: 2100px) and (max-width: 2400px)": {
          marginTop: "-16rem",
        },
        "@media (min-width: 2400px) and (max-width: 2600px)": {
          marginTop: "-18rem",
        },
        "@media (min-width: 2600px) and (max-width: 2800px)": {
          marginTop: "-19rem",
        },
        "@media (min-width: 2800px) and (max-width: 3000px)": {
          marginTop: "-21rem",
        },
        "@media (min-width: 3000px) and (max-width: 3600px)": {
          marginTop: "-23rem",
        },
        "@media (min-width:3600px)": {
          marginTop: "-27rem",
        },
      })}
    >
      <img
        src="./img/workShop.png"
        alt=""
        css={css({
          width: "100%",
          filter: "brightness(35%)",
          maxWidth: "100%",
          "@media (max-width:768px)": {
            width: "100%",
            height: "44rem",
            objectFit: "cover",
          },
          "@media (min-width: 768px) and (max-width: 1280px)": {
            height: "50rem",
            objectFit: "cover",
          },
        })}
      />

      <div
        css={css({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "absolute",
          top: "0",
          width: "100%",
          height: "100%",
          "@media (max-width:768px)": {
            gap: "2rem",
          },
        })}
      >
        <h1
          css={css({
            color: colors.whiteText,
            fontSize: "86px",
            fontWeight: "bolder",
            fontFamily: "Noto Sans",
            textAlign: "center",
            "@media (max-width:768px)": {
              fontSize: "36px",
            },
          })}
        >
          We’ve got your back
        </h1>
        <h2
          css={css({
            color: colors.whiteText,
            fontSize: "32px",
            fontWeight: "normal",
            textAlign: "center",
            padding: "0 14px",
            "@media (max-width:768px)": {
              fontSize: "26px",
              fontWeight: "bolder",
              lineHeight: "1.3",
            },
          })}
        >
          Book a <strong>Free</strong> meeting with our team
        </h2>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          bottom: "8%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <button
          type="button"
          onClick={handleCalendry()}
          css={css(boxStyle, {
            fontSize: "20px",
            backgroundColor: "transparent!important",
            width: "380px",
            height: "74px",
            marginTop: "8rem",
            "@media (max-width:768px)": {
              width: "327px",
            },
          })}
        >
          Book Your Intro Meeting
        </button>
      </div>
    </div>
  );
};

export default Book;
