function Location(props) {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.3223 18.2339C34.3223 26.0537 25.7436 33.4672 22.6201 35.9073C21.9558 36.4262 21.0462 36.4239 20.3842 35.902C17.2644 33.4424 8.67773 25.9701 8.67773 18.2339C8.67773 11.1524 14.4185 5.41162 21.5 5.41162C28.5816 5.41162 34.3223 11.1524 34.3223 18.2339Z"
        stroke="#006180"
        strokeWidth="1.5"
      />
      <circle cx="21.5002" cy="18.7053" r="5.54077" stroke="#006180" strokeWidth="1.5" />
    </svg>
  );
}
export default Location;
