import * as React from "react";

const TelegramMiniApp = (props) => (
  <svg
    width={321}
    height={320}
    viewBox="0 0 321 320"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2042_230)">
      <path
        d="M0.5 21.3334C0.5 9.55128 10.0513 0 21.8333 0H299.167C310.949 0 320.5 9.55126 320.5 21.3333V298.667C320.5 310.449 310.949 320 299.167 320H21.8334C10.0513 320 0.5 310.449 0.5 298.667V21.3334Z"
        fill="white"
      />
      <rect
        x={220.242}
        y={-60.4465}
        width={166.566}
        height={166.566}
        rx={83.2832}
        fill="#0D0080"
        fillOpacity={0.3}
      />
      <g filter="url(#filter0_d_2042_230)">
        <rect
          x={-10.1331}
          y={63.0901}
          width={341.266}
          height={401.152}
          rx={170.633}
          fill="#2283F7"
        />
      </g>
      <g filter="url(#filter1_d_2042_230)">
        <rect
          x={-7.20251}
          y={-12.0522}
          width={117.387}
          height={332.052}
          rx={11.1443}
          fill="#008044"
          fillOpacity={0.3}
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter2_d_2042_230)">
        <rect
          x={74.6393}
          y={34.7217}
          width={149.643}
          height={349.272}
          rx={11.1443}
          transform="rotate(-40.9441 74.6393 34.7217)"
          fill="#006180"
          fillOpacity={0.3}
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter3_d_2042_230)">
        <path
          d="M152.079 -13.7085C129.573 -31.1808 79.0542 -53.2486 57.0245 -1.74078C29.4873 62.644 96.2153 124.233 16.9489 132.917C-62.3176 141.601 -52.1584 175.965 -44.8631 218.467"
          stroke="#0AA2D9"
          strokeWidth={28.4444}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.0378 214.318L29.9461 189.285C17.6645 181.42 19.7397 162.884 33.4568 157.93L92.6062 136.567C103.685 118.502 120.394 104.493 136.726 90.7981L136.737 90.7889C141.174 87.0691 145.583 83.3725 149.844 79.624C180.427 52.721 222.652 79.7608 211.01 118.793C209.388 124.232 207.875 129.784 206.352 135.371C200.746 155.939 195.011 176.98 183.235 194.604L188.578 257.266C189.817 271.798 173.85 281.437 161.568 273.572L128.183 252.193L121.831 262.111C119.212 266.201 113.773 267.394 109.683 264.775L65.3497 236.385C61.2598 233.766 60.0671 228.326 62.6863 224.236L69.0378 214.318ZM174.752 152.474C167.62 163.61 152.811 166.857 141.674 159.725C130.538 152.594 127.291 137.784 134.422 126.648C141.554 115.511 156.364 112.265 167.5 119.396C178.637 126.528 181.884 141.337 174.752 152.474ZM77.7266 256.214C68.9418 250.589 57.2608 253.15 51.6356 261.934C49.0629 265.952 47.6233 270.59 47.4687 275.358L46.3965 308.51L76.0591 293.667C80.3253 291.531 83.9357 288.283 86.5085 284.266C92.1337 275.482 89.573 263.801 80.7882 258.175L77.7266 256.214Z"
        fill="white"
      />
      <mask
        id="mask0_2042_230"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={21}
        y={68}
        width={192}
        height={209}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.0378 214.318L29.9462 189.285C17.6645 181.42 19.7397 162.884 33.4568 157.93L92.6062 136.567C106.698 113.591 129.894 97.1745 149.844 79.624C180.427 52.721 222.652 79.7608 211.01 118.793C203.416 144.256 198.21 172.193 183.235 194.604L188.578 257.266C189.817 271.797 173.85 281.437 161.568 273.572L128.183 252.193L121.831 262.111C119.212 266.201 113.773 267.394 109.683 264.775L65.3497 236.385C61.2598 233.766 60.0671 228.326 62.6863 224.236L69.0378 214.318Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_2042_230)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.844 239.296L193.943 178.97C197.865 193.456 205.603 222.849 205.177 224.536C204.97 225.351 205.046 232.205 205.139 240.54C205.286 253.761 205.474 270.707 204.642 273.179C203.286 277.209 183.128 301.039 181.228 301.455C179.709 301.789 150.956 290.506 136.77 284.824L123.327 260.122L118.827 267.149C117.954 268.513 116.141 268.91 114.777 268.037L98.1974 257.42C96.834 256.546 96.4365 254.733 97.3096 253.37L110.71 232.445C111.583 231.082 113.396 230.684 114.759 231.557L126.844 239.296Z"
          fill="#77CBFC"
        />
        <path
          d="M184.372 153.828C203.392 120.925 212.276 75.0033 214.341 56.1553C220.8 103.502 203.867 162.711 183.367 194.725C168.504 217.934 142.259 244.794 131.202 254.08L107.41 238.844C105.476 237.883 101.979 235.067 103.467 231.497C105.328 227.033 106.758 228.361 114.632 222.731C122.507 217.101 160.596 194.957 184.372 153.828Z"
          fill="#CBEBFE"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_2042_230"
        x={-10.1331}
        y={63.0901}
        width={344.822}
        height={404.707}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2042_230" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2042_230"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2042_230"
        x={-7.20251}
        y={-12.0522}
        width={120.943}
        height={338.274}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={4.44444} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2042_230" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2042_230"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_2042_230"
        x={79.2158}
        y={-58.7664}
        width={336.321}
        height={358.956}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={4.44444} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2042_230" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2042_230"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_2042_230"
        x={-63.9481}
        y={-47.949}
        width={228.304}
        height={272.378}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2042_230" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2042_230"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_2042_230">
        <path
          d="M0.5 21.3334C0.5 9.55128 10.0513 0 21.8333 0H299.167C310.949 0 320.5 9.55126 320.5 21.3333V298.667C320.5 310.449 310.949 320 299.167 320H21.8334C10.0513 320 0.5 310.449 0.5 298.667V21.3334Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
export default TelegramMiniApp;
