/** @jsxImportSource @emotion/react */
import { colors } from "../theme";
import { css } from "@emotion/react";
import "../App.css";

const TextBox = (props) => {
  return (
    <div
      css={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "2rem",
        height: "20rem",
        marginLeft: props.isRight ? "0" : "7rem",
        marginBottom: "2rem",
        marginRight: props.isRight ? "2rem" : "0",

        "@media (max-width:1024px)": {
          marginLeft: "2rem",
          padding: "0",
        },
        "@media (min-width: 1024px) and (max-width: 1080px)": {
          height: "26rem",
        },
        "@media (min-width: 768px) and (max-width: 1080px)": {
          marginRight: "0",
        },
      }}
    >
      <props.icon
        css={css({
          position: "absolute",
          top: "-5%",
          ...(props.isRight ? { left: "0" } : { right: "0" }),
          "@media (max-width:1080px)": {
            left: "0",
            top: "-10%",
          },
        })}
      />

      <props.mood
        css={css({
          position: "absolute",
          right: props.isRight ? 0 : "6rem",
          top: "-20%",
          "@media (max-width:1080px)": {
            right: "5%",
          },
        })}
      />

      <div
        css={{
          position: "relative",
          display: "flex",
          justifyContent: props.isRight ? "left" : "",
          "@media (max-width:1024px)": {
            justifyContent: "center",
            maxWidth: "250px",
            marginLeft: "5rem",
          },
          "@media (min-width: 1024px) and (max-width: 1024px)": {
            marginTop: "3rem",
          },
        }}
      >
        <h3
          css={{
            fontSize: "26px",
            color: colors.primary,
            fontWeight: "bolder",
            textAlign: props.isRight && "center",
            marginLeft: props.isRight && "4rem",
            marginTop: "1.5rem",
            fontFamily: "Noto Sans",
            whiteSpace: "nowrap",

            "@media (max-width:640px)": {
              whiteSpace: "unset",
            },

            "@media (max-width:1024px)": {
              textAlign: "left",
              minWidth: "100%",
              marginLeft: "2rem",
            },
            "@media (min-width: 1024px) and (max-width: 1280px)": {
              marginTop: "5rem",
            },
            "@media (min-width: 1280px) and (max-width: 1440px)": {
              marginTop: "6rem",
            },
          }}
        >
          {props.title}
        </h3>
      </div>
      <div
        css={css({
          "@media (min-width: 1024px) and (max-width: 1024px)": {
            marginBottom: "4rem",
          },
        })}
      >
        <p
          css={{
            marginTop: "1rem",
            fontSize: "20px",
            maxWidth: "480px",
            fontWeight: "normal",
            "@media (max-width:1024px)": {
              marginRight: "1rem",
            },
          }}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};

export default TextBox;
