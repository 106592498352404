/** @jsxImportSource @emotion/react */
import { Link } from "react-scroll";
import { css } from "@emotion/react";
import { colors } from "../theme";

const Nav = (props) => {
  return (
    <Link
      activeClass="active"
      to={props.link}
      spy={false}
      smooth={true}
      offset={0}
      duration={700}
      href={props.link}
      onClick={props.onClick}
      css={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        scrollBehavior: "smooth",
        marginBottom: props.lastItem ? "0" : "1.5rem",

        svg: {
          "@media (max-width:768px)": {
            width: "55%",
          },
        },
        "@media (max-width:768px)": {
          marginBottom: "0",
        },
      })}
    >
      {props.icon}

      <p
        css={css({
          color: colors.whiteText,
          fontSize: "16px",
          lineHeight: "1",
          fontFamily: "Noto Sans",
          padding: "0",
          margin: "0",
          userSelect: "none",
          paddingTop: "4px",
          "@media (max-width:768px)": {
            fontSize: "16px",

            paddingTop: 0,
          },
        })}
      >
        {props.title}
      </p>
    </Link>
  );
};
export default Nav;
