import React from "react";

function NumberFive(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="83"
      height="82"
      fill="none"
      viewBox="0 0 83 82"
      {...props}
    >
      <path
        fill="#006180"
        d="M.667 24c0-13.255 10.745-24 24-24h34c13.255 0 24 10.745 24 24v34c0 13.255-10.745 24-24 24h-34c-13.255 0-24-10.745-24-24V24z"
      ></path>
      <path
        fill="#fff"
        d="M42.717 36.9c2.1 0 3.983.433 5.65 1.3 1.666.867 2.983 2.133 3.95 3.8.966 1.633 1.45 3.65 1.45 6.05 0 2.633-.534 4.883-1.6 6.75-1.067 1.833-2.667 3.25-4.8 4.25-2.1.967-4.75 1.45-7.95 1.45-1.967 0-3.784-.167-5.45-.5-1.667-.333-3.134-.817-4.4-1.45V51.4c1.233.6 2.716 1.133 4.45 1.6 1.766.467 3.4.7 4.9.7 1.366 0 2.5-.183 3.4-.55.933-.4 1.65-.967 2.15-1.7.5-.767.75-1.733.75-2.9 0-1.567-.55-2.767-1.65-3.6-1.067-.867-2.717-1.3-4.95-1.3-.9 0-1.817.1-2.75.3-.934.167-1.734.333-2.4.5l-3.25-1.65 1.35-18.5h19.8v7.15h-12.5l-.55 5.95c.566-.1 1.15-.2 1.75-.3.6-.133 1.483-.2 2.65-.2z"
      ></path>
    </svg>
  );
}

export default NumberFive;
