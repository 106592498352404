/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { colors } from "../theme";

const ProductDesign = (props) => {
  return (
    <div
      css={css({
        maxWidth: "321px",
      })}
    >
      <div
        css={css({
          display: "flex",
          position: "relative",
          "@media (max-width:768px)": {
            justifyContent: "center",
          },
        })}
      >
        <props.curve />
        <img
          src={props.icon}
          alt={props.title}
          css={css({
            width: "300px",
            height: "300px",
            display: "flex",
            position: "absolute",
          })}
          className="animated-image"
        />
      </div>

      <div>
        <h2
          css={css({
            color: colors.whiteText,
            fontSize: "32px",
            fontWeight: "lighter",
            textAlign: "center",
            textWrap: "nowrap",
          })}
        >
          {props.title}
        </h2>
        <p
          css={css({
            color: colors.whiteText,
            fontSize: "20px",
            fontWeight: "lighter",
            fontFamily: "Noto Sans",
            textAlign: "center",
          })}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};
export default ProductDesign;
