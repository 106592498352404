/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "../App.css";
import FirstCircle from "../icons/FirstCircle";
import SecondIcon from "../icons/SecondIcon";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const AnimationContainer = (props) => {
  const [currentIcon, setCurrentIcon] = useState(1);

  useEffect(() => {
    const sequence = async () => {
      await new Promise((resolve) => setTimeout(resolve, 300));
      setCurrentIcon(2);
    };
    sequence();
  }, []);

  return (
    <div
      className="icon-container"
      css={css({
        width: "100%",
        height: "20rem",
        "@media (max-width: 768px)": {
          width: "70%",
        },
        "@media (min-width: 320px) and (max-width: 380px)": {
          marginTop: props.isAds ? "-8rem" : "",
        },
        "@media (min-width: 380px) and (max-width: 490px)": {
          marginTop: props.isAds ? "-6rem" : "",
        },
        "@media (min-width: 490px) and (max-width: 560px)": {
          marginTop: props.isAds ? "-3rem" : "",
        },
        "@media (min-width: 560px) and (max-width: 600px)": {
          marginTop: props.isAds ? "-2.5rem" : "",
        },
        "@media (min-width: 600px) and (max-width: 640px)": {
          marginTop: props.isAds ? "-2rem" : "",
        },
        "@media (min-width: 640px) and (max-width: 768px)": {
          marginTop: props.isAds ? "-rem" : "",
        },
      })}
    >
      {currentIcon === 1 && (
        <motion.div
          key="icon1"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          css={css({
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            "@media (max-width: 768px)": { width: "70%" },
            "@media (min-width: 320px) and (max-width: 380px)": {
              marginTop: props.isAds ? "-8rem" : "",
            },
            // ... other media queries
          })}
        >
          <FirstCircle />
        </motion.div>
      )}
      {currentIcon === 2 && (
        <motion.div
          key="icon2"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, type: "spring", stiffness: "200", damping: 10 }}
          css={css({
            width: "100%",

            "@media (max-width: 768px)": {
              position: "absolute",
              right: "0",
              top: "12%",
            },
          })}
        >
          <SecondIcon />
        </motion.div>
      )}
    </div>
  );
};

export default AnimationContainer;
