import * as React from "react";

const WaveTop = (props) => (
  <svg viewBox="0 0 1641 178" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1592.46 60.9667L1599.04 63.9752C1649.13 86.8869 1689.3 127.042 1712.24 177.115H-0.00012207L21.6232 164.447C128.938 101.58 253.969 75.7985 377.399 91.0864L511.086 107.645C577.398 115.858 644.213 96.8943 696.324 55.0694C758.2 5.40694 840.142 -11.5607 916.652 9.44666L1158.57 75.8716C1227.28 94.7366 1300.17 91.619 1367.01 66.9562L1402.69 53.7924C1464.41 31.0199 1532.64 33.5992 1592.46 60.9667Z"
      fill="#006180"
    />
  </svg>
);

export default WaveTop;
