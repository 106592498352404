import React from "react";

function NumberTwo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="82"
      height="82"
      fill="none"
      viewBox="0 0 82 82"
      {...props}
    >
      <path
        fill="#006180"
        d="M0 24C0 10.745 10.745 0 24 0h34c13.255 0 24 10.745 24 24v34c0 13.255-10.745 24-24 24H24C10.745 82 0 71.255 0 58V24z"
      ></path>
      <path
        fill="#fff"
        d="M53.65 60h-25.4v-5.75l8.85-8.95c1.8-1.867 3.233-3.4 4.3-4.6 1.067-1.2 1.817-2.25 2.25-3.15a6.51 6.51 0 00.7-2.95c0-1.267-.367-2.183-1.1-2.75-.733-.6-1.683-.9-2.85-.9-1.233 0-2.467.317-3.7.95s-2.567 1.55-4 2.75l-4.65-5.45a31.452 31.452 0 013.3-2.6c1.2-.833 2.583-1.5 4.15-2 1.567-.533 3.467-.8 5.7-.8 2.4 0 4.45.433 6.15 1.3 1.733.833 3.067 1.983 4 3.45.967 1.433 1.45 3.083 1.45 4.95 0 1.5-.217 2.9-.65 4.2-.433 1.267-1.083 2.5-1.95 3.7-.833 1.167-1.883 2.383-3.15 3.65s-2.7 2.65-4.3 4.15l-3.7 3.4v.3h14.6V60z"
      ></path>
    </svg>
  );
}

export default NumberTwo;
